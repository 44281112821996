import { Box, Card, Stack, Typography, Divider, Button } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";

interface Props {
  orderId: number;
}

const ManualPlanStatusPage = ({ orderId }: Props): JSX.Element => {
  const navigate = useNavigate();
  return (
    <Stack gap={4}>
      <Card>
        <Box flex={1}>
          <Stack
            direction="row"
            padding={2}
            justifyContent="space-between"
            alignContent="center"
          >
            <Stack>
              <Typography variant="subtitle1">Manual planning</Typography>
              <Typography variant="body2" color="text.secondary">
                Plan delivery method
              </Typography>
            </Stack>
            <Button
              sx={{ alignSelf: "center", height: "max-content" }}
              size="medium"
              variant="outlined"
              onClick={() => navigate(`/fulfillment/${orderId}/automation`)}
            >
              Change
            </Button>
          </Stack>
        </Box>
      </Card>
      <Divider sx={{ width: 1 }} />
      <Box flex={1}>
        <Stack gap={2}>
          <Typography variant="h6">Handover</Typography>
          <Typography variant="body2">
            By marking this as complete, you confirm that the physician has
            approved the plan and the handover process with the local planning
            team is finished
          </Typography>
          <DateTimePicker
            sx={{ width: "max-content", marginTop: 1 }}
            label="Date time completed"
            defaultValue={dayjs()}
            format="L hh:mm a"
          />
          <Button sx={{ width: "max-content" }} variant="contained">
            Mark as complete
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};

export default ManualPlanStatusPage;
