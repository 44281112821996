import {
  Stack,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import dayjs from "dayjs";
import { WarningAmberOutlined, ErrorOutline } from "@mui/icons-material";

export interface Event {
  updatedAt: string;
  eventDetails: string;
  eventType: string;
}

interface StatusPageDialogProps {
  errors: Event[];
  warnings: Event[];
  open: boolean;
  onClose: () => void;
}

interface EventsProps {
  events: Event[];
}

export const Events = ({ events }: EventsProps) => {
  return events.map((event, index) => (
    <Stack key={index} direction="row" gap={1}>
      <Typography variant="body2">{`${index + 1}.`}</Typography>
      <Typography variant="body2">{`[${dayjs(event.updatedAt).format("L, HH:mm:ss")}] ${event.eventDetails}`}</Typography>
    </Stack>
  ));
};

export const StatusPageDialog = ({
  errors,
  warnings,
  open,
  onClose,
}: StatusPageDialogProps) => {
  const showSubHeadings = errors?.length > 0 && warnings?.length > 0;

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={onClose}
      scroll={"paper"}
    >
      <DialogTitle>Automation details</DialogTitle>
      <DialogContent dividers>
        <Stack gap={2}>
          {showSubHeadings && (
            <Typography variant="subtitle2">Plan error</Typography>
          )}
          <Events events={errors} />
          {showSubHeadings && (
            <Typography variant="subtitle2">Plan warning</Typography>
          )}
          <Events events={warnings} />
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

interface WarningBoxProps {
  onClick: () => void;
  numberOfWarnings: number;
}

export const WarningBox = ({ onClick, numberOfWarnings }: WarningBoxProps) => {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{ marginTop: 2, padding: 1, backgroundColor: "warning.light" }}
    >
      <WarningAmberOutlined color="warning" fontSize="small" />
      <Stack>
        <Typography variant="subtitle2" color="warning.dark">
          Warnings
        </Typography>
        <Stack direction="row" alignItems="center" gap={0.3}>
          <Typography variant="body2" color="warning.dark">
            {numberOfWarnings} warning{numberOfWarnings === 1 ? "" : "s"}{" "}
            detected and skipped
          </Typography>
          <Button
            onClick={onClick}
            sx={{ textDecoration: "underline", textTransform: "uppercase" }}
            size="small"
            color="warning"
            variant="text"
          >
            VIEW DETAILS
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

interface ErrorBoxProps {
  onClick: () => void;
}

export const ErrorBox = ({ onClick }: ErrorBoxProps) => {
  return (
    <Stack
      direction="row"
      gap={1}
      sx={{ marginTop: 2, padding: 1, backgroundColor: "error.light" }}
    >
      <ErrorOutline color="error" fontSize="small" />
      <Stack>
        <Typography variant="subtitle2" color="error.dark">
          Plan failed
        </Typography>
        <Stack direction="row" alignItems="center" gap={0.3}>
          <Typography variant="body2" color="error.dark">
            Plan cannot be completed. Please complete the plan manually.
          </Typography>
          <Button
            onClick={onClick}
            sx={{ textDecoration: "underline", textTransform: "uppercase" }}
            size="small"
            color="error"
            variant="text"
          >
            VIEW DETAILS
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
