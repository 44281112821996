import RODashboard from "./Dashboard/RODashboard";
import DosimetristDashboard from "./Dashboard/DosimetristDashboard";
import OrderPage from "./Order/OrderPage";
import FulfillmentPage from "./Fulfillment/FulfillmentPage";
import ErrorPage from "./Shared/ErrorPage/ErrorPage";
import SentryErrorPage from "./Shared/ErrorPage/SentryErrorPage";
import RoOrderTrackerPage from "./OrderTracker/RoOrderTrackerPage";
import DosiOrderTrackerPage from "./OrderTracker/DosiOrderTrackerPage";
import DemoDosiOrderTrackerPage from "./Demo/DemoDosiOrderTrackerPage";
import DemoCommandCenter from "./Demo/DemoCommandCenter/DemoCommandCenter";
import CommandCenter from "./CommandCenter/CommandCenter";
import DosiAutomationTrackerPage from "./OrderTracker/DosiAutomationTrackerPage";

export {
  RODashboard,
  DosimetristDashboard,
  OrderPage,
  FulfillmentPage,
  ErrorPage,
  SentryErrorPage,
  RoOrderTrackerPage,
  DosiOrderTrackerPage,
  CommandCenter,
  DosiAutomationTrackerPage,
  DemoDosiOrderTrackerPage,
  DemoCommandCenter,
};

// Patching out some console warnings that are currently unavoidable so they don't clutter up the console for devs
const originalConsoleWarn = console.warn;
console.warn = (...args) => {
  if (
    args[0].includes(
      "A textarea element was provided to Autocomplete where input was expected.",
    )
  ) {
    return;
  }
  originalConsoleWarn(...args);
};
