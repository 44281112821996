import theme from "../../theme/theme";
import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { Typography, Avatar, Stack, Badge } from "@mui/material";
import { Role } from "@enums";
import { useAppSelector } from "@hooks";
import { NotificationOut, OrderOut } from "@providers/hop-ord-server/api";
import { dateOfBirthFormat, getAvatarText, timeFromNow } from "@utils";

import { OrderStatus } from "@enums";
interface NotificationItemProps {
  notification: NotificationOut;
  toggleDrawer: () => void;
  orders: OrderOut[];
}

const NotificationItem = ({
  notification,
  toggleDrawer,
  orders,
}: NotificationItemProps) => {
  const navigate = useNavigate();
  const roles: Role[] = useAppSelector((state) => state?.auth?.roles);
  const handleOrderSelect = useCallback(
    (orderId: number, orderGroupId: number) => {
      toggleDrawer();

      // Since the Order could've been edited since the Notification was created we want
      // to ensure that the Notification redirects to the most recent version of the order
      const [latestOrder] = orders.filter(
        (order: OrderOut) => order.orderGroupId === orderGroupId,
      );
      const redirectId = latestOrder ? latestOrder.id : orderId;

      let path = roles.includes(Role.DOSIMETRIST)
        ? `/fulfillment/${redirectId}`
        : `/order/${redirectId}`;

      if (latestOrder && latestOrder.status !== OrderStatus.DRAFT) {
        path = `${path}/tracker`;
      }

      navigate(path);
    },
    [navigate, toggleDrawer, roles, orders],
  );

  if (!notification.orderId || !notification.orderGroupId) return <></>;

  const mrn = notification.patientMrn || "-";
  const dob = dateOfBirthFormat(notification.patientDob || "");

  return (
    <Stack
      direction="row"
      alignItems="top"
      padding="24px 8px"
      sx={{
        ":hover": {
          backgroundColor: theme.palette.action.hover,
          cursor: "pointer",
        },
      }}
      justifyContent="space-between"
      onClick={() =>
        handleOrderSelect(
          notification.orderId || 0,
          notification.orderGroupId || 0,
        )
      }
    >
      <Stack direction="row" gap={1}>
        <Stack direction="row" alignSelf="baseline" alignItems="center" gap={1}>
          <Badge
            color="error"
            variant="dot"
            invisible={notification.viewed || false}
          ></Badge>
          <Avatar>{getAvatarText(notification.createdBy || "")}</Avatar>
        </Stack>
        <Stack direction="column" gap={0.1}>
          <Typography variant="h6">{notification.content}</Typography>
          <Typography variant="body1">{notification.patientName}</Typography>
          <Typography
            variant="body2"
            sx={{ color: "rgb(0,0,0,.6)", fontSize: "14px" }}
          >
            {`MRN: ${mrn} DOB: ${dob}`}
          </Typography>
        </Stack>
      </Stack>
      <Typography variant="caption" color="grey.700">
        {timeFromNow(notification.createdAt, "date")}
      </Typography>
    </Stack>
  );
};

export default NotificationItem;
