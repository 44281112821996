import {
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Divider,
  Stack,
} from "@mui/material";
import { useState } from "react";
import {
  useAutomationIntegrationApiV1MockImportedCtRtStructResponseMutation,
  useAutomationIntegrationApiV1MockPendingCtRtStructResponseMutation,
} from "@providers/hop-ord-server/api";

interface Props {
  orderId: number;
  ctOnly: boolean;
}

type ResponseType = "empty" | "ct_only" | "ct_rt_struct";

export const CtRtMockComponent = ({ orderId, ctOnly }: Props) => {
  const [mockStatus, setMockStatus] = useState<string>("");

  const [createMockImportedCtRtResponse] =
    useAutomationIntegrationApiV1MockImportedCtRtStructResponseMutation();

  const [createMockPendingCtRtResponse] =
    useAutomationIntegrationApiV1MockPendingCtRtStructResponseMutation();

  const createImportedCtRtResponse = (
    orderId: number,
    responseType: ResponseType,
  ) => {
    createMockImportedCtRtResponse({
      orderId,
      responseType,
    });
  };

  const createPendingCtRtResponse = (
    orderId: number,
    responseType: ResponseType,
  ) => {
    createMockPendingCtRtResponse({
      orderId,
      responseType,
    });
  };

  const handleCreateMockStatus = () => {
    if (ctOnly) {
      if (mockStatus === "imported_data") {
        createImportedCtRtResponse(orderId, "ct_only");
      }
      if (mockStatus === "pending_data") {
        createPendingCtRtResponse(orderId, "ct_only");
      }
      if (mockStatus === "empty_data") {
        createPendingCtRtResponse(orderId, "empty");
      }
    } else {
      if (mockStatus === "imported_data") {
        createImportedCtRtResponse(orderId, "ct_rt_struct");
      }
      if (mockStatus === "pending_data") {
        createPendingCtRtResponse(orderId, "ct_rt_struct");
      }
      if (mockStatus === "empty_data") {
        createPendingCtRtResponse(orderId, "empty");
      }
    }

    setMockStatus("");
  };

  return (
    <Stack gap={2}>
      <Divider />
      <FormControl>
        <InputLabel id="mock-struct-label">Response Type</InputLabel>
        <Select
          value={mockStatus}
          labelId="mock-struct-label"
          label="Mock struct type"
          onChange={(e) => setMockStatus(e.target.value)}
          size="small"
          sx={{
            width: "200px",
          }}
        >
          <MenuItem value={"imported_data"}>Imported</MenuItem>
          <MenuItem value={"pending_data"}>Pending</MenuItem>
          <MenuItem value={"empty_data"}>Empty</MenuItem>
        </Select>
      </FormControl>
      <Stack alignItems="center" justifyContent="space-between" paddingX={1}>
        <Button
          color="primary"
          variant="outlined"
          onClick={handleCreateMockStatus}
        >
          <Typography variant="caption">Mock Response</Typography>
        </Button>
      </Stack>
    </Stack>
  );
};
