import { useEffect, useState } from "react";
import {
  Autocomplete,
  TextField,
  Stack,
  Typography,
  ToggleButton,
  ToggleButtonGroup,
  Chip,
  Divider,
  Tooltip,
  Button,
  CircularProgress,
} from "@mui/material";
import PlanningTemplate from "../components/PlanningTemplate";
import {
  useAutomationIntegrationApiV1ListTemplatesQuery,
  useAutomationIntegrationApiV1ListTemplateDetailsQuery,
  useAutomationIntegrationApiV1GetStructuresQuery,
  useAutomationIntegrationApiV1SendRequestMutation,
  useAutomationIntegrationApiV1SendPlanGenerationMutation,
  useWebApiV1OrderQuery,
  useWebApiV1UpdateOrderMutation,
  OrderStatus,
} from "@providers/hop-ord-server/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { Cancel, CheckCircle } from "@mui/icons-material";
import { OrderStatus as OrderStatusEnum } from "@enums";
import { useNavigate } from "react-router-dom";

interface Props {
  orderId: number;
  defaultSelectedButton?: string;
}

const PlanningTemplateApi = ({
  orderId,
  defaultSelectedButton,
}: Props): JSX.Element => {
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = useState<string>("");
  const [structuresRetrieved, setStructuresRetrieved] =
    useState<boolean>(false);
  const [structuresRequested, setStructuresRequested] =
    useState<boolean>(false);
  const [selectedButton, setSelectedButton] = useState<string>(
    defaultSelectedButton || "",
  );

  const [updateOrder] = useWebApiV1UpdateOrderMutation();

  const { data: order, isLoading: orderLoading } = useWebApiV1OrderQuery({
    orderId,
  });
  const rtStructExists = !!order?.rtstructStatus;
  const ctExists = !!order?.ctStatus;
  const { data: templateNames, isLoading: templateNamesLoading } =
    useAutomationIntegrationApiV1ListTemplatesQuery();

  const { data: template } =
    useAutomationIntegrationApiV1ListTemplateDetailsQuery(
      selectedTemplate ? { templateName: selectedTemplate } : skipToken,
    );

  const [sendPlan] = useAutomationIntegrationApiV1SendPlanGenerationMutation();
  const [sendAvailableStructuresRequest] =
    useAutomationIntegrationApiV1SendRequestMutation();

  const setOrderStatus = (status: OrderStatus): Promise<any> => {
    return updateOrder({
      orderId,
      orderIn: {
        status,
      },
    });
  };

  const templateOptions = [
    "",
    ...(templateNames?.map(
      (templateName: { name: string }) => templateName.name,
    ) || []),
  ];

  const { data: availableStructures, isLoading: availableStructuresLoading } =
    useAutomationIntegrationApiV1GetStructuresQuery(
      {
        orderId,
      },
      {
        pollingInterval: 500,
        skip: !selectedTemplate || structuresRetrieved,
      },
    );

  useEffect(() => {
    if (
      !structuresRetrieved &&
      !structuresRequested &&
      !availableStructuresLoading
    ) {
      sendAvailableStructuresRequest({ orderId });
      setStructuresRequested(true);
    }

    if (availableStructures?.structures?.length) {
      setStructuresRetrieved(true);
      return;
    }
  }, [
    structuresRetrieved,
    availableStructuresLoading,
    structuresRequested,
    availableStructures,
    orderId,
  ]);

  if (templateNamesLoading) return <></>;

  return (
    <Stack width={1} height={1} marginBottom={3} gap={2}>
      <Divider />
      <Stack direction="row" gap={1} paddingTop={1}>
        <Chip
          color={orderLoading ? "secondary" : ctExists ? "success" : "error"}
          icon={
            orderLoading ? (
              <CircularProgress size={18} />
            ) : ctExists ? (
              <CheckCircle />
            ) : (
              <Cancel />
            )
          }
          variant="outlined"
          label="CT Exists"
        />
        <Chip
          color={
            orderLoading ? "secondary" : rtStructExists ? "success" : "error"
          }
          icon={
            orderLoading ? (
              <CircularProgress size={18} />
            ) : rtStructExists ? (
              <CheckCircle />
            ) : (
              <Cancel />
            )
          }
          variant="outlined"
          label="RTStruct Exists"
        />
      </Stack>
      <Typography variant="h6">Plan delivery method</Typography>
      <Stack direction="row" justifyContent="space-between">
        <ToggleButtonGroup
          sx={{ width: "350px" }}
          value={selectedButton}
          exclusive
          color="primary"
          onChange={(_, newAlignment) =>
            newAlignment !== null ? setSelectedButton(newAlignment) : null
          }
        >
          <Tooltip
            arrow
            title={!rtStructExists ? "Missing RTStruct file" : null}
          >
            <span style={{ width: "100%" }}>
              <ToggleButton
                disabled={!rtStructExists}
                fullWidth
                value={OrderStatusEnum.SENT_TO_AUTOMATION}
              >
                Automated Plan
              </ToggleButton>
            </span>
          </Tooltip>
          <ToggleButton fullWidth value={OrderStatusEnum.MANUAL_PLAN}>
            Manual Plan
          </ToggleButton>
        </ToggleButtonGroup>
      </Stack>
      {selectedButton === OrderStatusEnum.SENT_TO_AUTOMATION ? (
        <Stack gap={2} width={1}>
          <Autocomplete
            options={templateOptions || []}
            value={selectedTemplate}
            fullWidth
            onChange={(_: any, newValue: string | null) => {
              setSelectedTemplate(newValue || "");
            }}
            renderInput={(params) => (
              <TextField {...params} label="Automated Template" />
            )}
          />
          {selectedTemplate && (
            <PlanningTemplate
              orderId={orderId}
              template={template}
              availableStructures={availableStructures}
              sendPlan={sendPlan}
              selectedTemplate={selectedTemplate}
              setOrderStatus={() => {
                setOrderStatus("sent_to_automation");
              }}
            />
          )}
        </Stack>
      ) : selectedButton === OrderStatusEnum.MANUAL_PLAN ? (
        <Stack gap={2} width={1}>
          <Typography variant="subtitle1">
            Plan delivery method can be changed later, if needed.
          </Typography>
          <Divider />
          <Button
            size="large"
            variant="contained"
            sx={{ width: "max-content" }}
            onClick={() =>
              setOrderStatus("manual_plan").then(() => {
                navigate("manual");
              })
            }
          >
            Submit
          </Button>
        </Stack>
      ) : (
        <Divider />
      )}
    </Stack>
  );
};

export default PlanningTemplateApi;
