import { useAutomationIntegrationApiV1CreateMockStatusMutation } from "@providers/hop-ord-server/api";
import {
  Stack,
  Button,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Typography,
  Divider,
} from "@mui/material";
import { useState } from "react";

interface Props {
  eventDetails: string[];
  orderId: number;
}

export const StatusPageMockComponent = ({ orderId, eventDetails }: Props) => {
  const [createMockStatus] =
    useAutomationIntegrationApiV1CreateMockStatusMutation();
  const [status, setStatus] = useState<string>("");
  const [eventDetail, setEventDetail] = useState<string>("");

  const sendToBackend = () => {
    createMockStatus({
      orderId,
      statusType: status,
      eventDetails: status === "planning_step" ? eventDetail : null,
    });
  };

  return (
    <Stack gap={2}>
      <Divider />
      <Typography variant="body2" color="text.secondary">
        YOU ARE ONLY SEEING THIS AS YOU ARE IN A DEMO TENANT
      </Typography>
      <Stack direction="row" width={1} gap={2}>
        <FormControl>
          <InputLabel id="mock-event-label">Mock event type</InputLabel>
          <Select
            value={status}
            labelId="mock-event-label"
            label="Mock event type"
            onChange={(e) => setStatus(e.target.value)}
            size="small"
            sx={{
              width: "200px",
            }}
          >
            <MenuItem value={"planning_step"}>Planning Step</MenuItem>
            <MenuItem value={"warning"}>Warning</MenuItem>
            <MenuItem value={"error"}>Error</MenuItem>
            <MenuItem value={"success"}>Success</MenuItem>
          </Select>
        </FormControl>
        <FormControl>
          <InputLabel id="mock-event-details-label">Event details</InputLabel>
          <Select
            value={eventDetail}
            labelId="mock-event-details-label"
            label="Event details"
            onChange={(e) => setEventDetail(e.target.value)}
            disabled={status !== "planning_step"}
            size="small"
            sx={{
              width: "200px",
            }}
          >
            {eventDetails.map((eventDetail, index) => (
              <MenuItem key={index} value={eventDetail}>
                {eventDetail}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <Button
          disabled={!status || (status == "planning_step" && !eventDetail)}
          onClick={sendToBackend}
        >
          Create Mock Status
        </Button>
      </Stack>
    </Stack>
  );
};
