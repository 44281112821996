import {
  Box,
  Card,
  Stack,
  Typography,
  Divider,
  LinearProgress,
  Button,
} from "@mui/material";
import {
  EventOut,
  PlanResponseOut,
  useAutomationIntegrationApiV1GetOrderEventsQuery,
  useAutomationIntegrationApiV1GetOrderTreatmentPlanResultQuery,
  useWebApiV1OrderStatusChangeQuery,
} from "@providers/hop-ord-server/api";
import { useAppSelector } from "@hooks";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { useState, useEffect } from "react";
import dayjs from "dayjs";
import {
  StatusPageDialog,
  Event,
  WarningBox,
  ErrorBox,
} from "../components/StatusPageComponents";
import { StatusPageMockComponent } from "../components/StatusPageMockComponent";

const PLANNING_STEP_SEQUENCE = "planning_step_sequence";
const PLANNING_STEP = "planning_step";
const WARNING = "warning";
const ERROR = "error";

const DEMO_TENANTS = [
  "tenant:demo",
  "tenant:demo-au",
  "tenant:demo-planning",
  "tenant:demo-planning-au",
];

interface Props {
  orderId: number;
}

interface TimerProps {
  startTime: dayjs.Dayjs | null;
  completedTime: dayjs.Dayjs | null;
}

const Timer = ({ startTime, completedTime }: TimerProps) => {
  const [seconds, setSeconds] = useState<number>(0);

  const getTime = () => {
    // Get difference between current time and start time
    const current = completedTime ? completedTime : dayjs();
    const difference = current.diff(startTime, "second");
    setSeconds(difference);
  };

  const formatTime = () => {
    const d = Math.floor(seconds / (3600 * 24));
    const h = Math.floor((seconds % (3600 * 24)) / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor(seconds % 60);

    const dDisplay = d > 0 ? d + (d == 1 ? " day " : " days ") : "";
    const hDisplay = h > 0 ? h + (h == 1 ? " hr " : " hrs ") : "";
    const mDisplay = m > 0 ? m + (m == 1 ? " min " : " mins ") : "";
    const sDisplay = s > 0 ? s + (s == 1 ? " sec" : " secs") : "";
    return dDisplay + hDisplay + mDisplay + sDisplay;
  };

  useEffect(() => {
    const interval = setInterval(() => getTime(), 200);
    return () => clearInterval(interval);
  }, [startTime, completedTime]);

  return (
    <Typography variant="body2" color="text.secondary">
      {startTime ? formatTime() : "-"}
    </Typography>
  );
};

const AutomatedPlanStatusPage = ({ orderId }: Props): JSX.Element => {
  const { data: orderStatus } = useWebApiV1OrderStatusChangeQuery({
    orderId,
    statusName: "sent_to_automation",
  });
  const { data: orderEvents } =
    useAutomationIntegrationApiV1GetOrderEventsQuery(
      { orderId },
      { pollingInterval: 5000 },
    );
  const { data: treatmentPlanResult } =
    useAutomationIntegrationApiV1GetOrderTreatmentPlanResultQuery(
      { orderId },
      { pollingInterval: 5000 },
    );
  const startTime = orderStatus ? dayjs(orderStatus?.createdAt) : null;

  const [currentStep, setCurrentStep] = useState<number>(0);
  const [currentStatus, setCurrentStatus] = useState<string>("Queued");
  const [showStatusDialog, setShowStatusDialog] = useState<boolean>(false);

  // Split order events into several types: warning, planning_step, planning_step_sequency
  const planningStepSequence = orderEvents?.filter(
    (event: EventOut) => event.event_type === PLANNING_STEP_SEQUENCE,
  );
  const planningSteps = orderEvents?.filter(
    (event: EventOut) => event.event_type === PLANNING_STEP,
  );
  const steps = planningStepSequence?.[0]?.event_details || [];
  const warnings =
    orderEvents
      ?.filter((event: EventOut) => event.event_type === WARNING)
      .map(
        (event: EventOut): Event => ({
          eventType: event.event_type,
          eventDetails: event.event_details as string,
          updatedAt: event.updated_at,
        }),
      ) || [];
  const errors =
    treatmentPlanResult
      ?.filter((planResult: PlanResponseOut) => planResult.error !== null)
      .map(
        (planResult: PlanResponseOut): Event => ({
          eventType: ERROR,
          eventDetails: planResult.error || "",
          updatedAt: planResult.updated_at,
        }),
      ) || [];
  const error = errors?.length > 0;
  const success = treatmentPlanResult?.length
    ? !treatmentPlanResult?.at(-1)?.error
    : false;
  const completedTime = treatmentPlanResult?.length
    ? dayjs(treatmentPlanResult?.at(-1)?.updated_at)
    : null;

  const tenants = useAppSelector((state) => state?.auth?.tenants);
  const isDemoTenant =
    tenants.filter((value) => DEMO_TENANTS.includes(value)).length > 0;

  // Set max steps and current step based on what is in the planning step sequence
  useEffect(() => {
    if (steps.length === 0) {
      setCurrentStep(0);
      setCurrentStatus("Queued");
      return;
    }

    const latestStep = planningSteps?.slice(-1)[0]?.event_details || ""; // This is always a string
    const latestStepIndex = steps?.indexOf(latestStep as string) + 1 || 0;
    // Normalise the current step to a percentage
    const normalise = (value: number) =>
      ((value - 0) * 100) / (steps.length - 0);
    setCurrentStep(normalise(latestStepIndex));
    setCurrentStatus(
      `${latestStepIndex}/${steps.length} ${latestStep as string}`,
    );
  }, [orderEvents?.length]);

  const elapsedTimeSubtext = (): string => {
    if (success) return "Completed | Elapsed time:";
    if (error) return "Failed | Elapsed time:";
    if (planningSteps?.length) return "Processing | Elapsed time:";
    return "Not started";
  };
  const hasStarted = elapsedTimeSubtext() !== "Not started";

  return (
    <Stack gap={4}>
      <Card>
        <Box flex={1}>
          <Stack padding={2} width={1}>
            <Typography variant="subtitle1">Planning automation</Typography>
            <Stack direction="row" gap={0.5}>
              <Typography variant="body2" color="text.secondary">
                {elapsedTimeSubtext()}
              </Typography>
              {hasStarted && (
                <Timer startTime={startTime} completedTime={completedTime} />
              )}
            </Stack>
            <LinearProgress
              sx={{ marginBottom: 2, marginTop: 3 }}
              variant="determinate"
              value={currentStep}
              color={error ? "error" : success ? "success" : "secondary"}
            />
            <Typography variant="body2" color="text.secondary">
              {currentStatus}
            </Typography>
            {error ? (
              <ErrorBox onClick={() => setShowStatusDialog(true)} />
            ) : warnings.length ? (
              <WarningBox
                onClick={() => setShowStatusDialog(true)}
                numberOfWarnings={warnings.length}
              />
            ) : null}
          </Stack>
        </Box>
      </Card>
      {isDemoTenant && (
        <StatusPageMockComponent
          orderId={orderId}
          eventDetails={steps as string[]}
        />
      )}
      <Divider sx={{ width: 1 }} />
      <Box flex={1}>
        <Stack gap={2}>
          <Typography variant="h6">Handover</Typography>
          <Typography variant="body2">
            By marking this as complete, you confirm that the physician has
            approved the plan and the handover process with the local planning
            team is finished
          </Typography>
          <DateTimePicker
            sx={{ width: "max-content", marginTop: 1 }}
            label="Date time completed"
            defaultValue={dayjs()}
            format="L hh:mm a"
          />
          <Button sx={{ width: "max-content" }} variant="contained">
            Mark as complete
          </Button>
        </Stack>
      </Box>
      <StatusPageDialog
        warnings={warnings}
        errors={errors}
        open={showStatusDialog}
        onClose={() => setShowStatusDialog(false)}
      />
    </Stack>
  );
};

export default AutomatedPlanStatusPage;
