import { useEffect } from "react";
import PlanningTemplateApi from "./pages/PlanningTemplateApi";
import AutomatedPlanStatusPage from "./pages/AutomatedPlanStatusPage";
import ManualPlanStatusPage from "./pages/ManualPlanStatusPage";
import { Stack } from "@mui/material";
import { Routes, Route } from "react-router-dom";

interface Props {
  orderId: number;
  orderStatus: string;
}

const PlanningAutomation = ({ orderId, orderStatus }: Props): JSX.Element => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [status]);

  return (
    <Stack width={1} height={1} marginTop={2} marginBottom={2} gap={2}>
      <Routes>
        <Route
          path="/"
          index
          element={
            <PlanningTemplateApi
              orderId={orderId}
              defaultSelectedButton={orderStatus}
            />
          }
        />
        <Route
          path="/automated"
          element={<AutomatedPlanStatusPage orderId={orderId} />}
        />
        <Route
          path="/manual"
          element={<ManualPlanStatusPage orderId={orderId} />}
        />
      </Routes>
    </Stack>
  );
};

export default PlanningAutomation;
