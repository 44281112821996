import {
  ActionBar,
  AutoScroll,
  Button,
  OrderHeader,
  PatientDetailsForm,
  PatientInputTypes,
} from "@components";
import SiteGroupPanel, {
  SiteGroupPanelRefObject,
} from "@components/SiteGroupPanel/SiteGroupPanel";
import { Role } from "@enums";
import {
  Delete as DeleteIcon,
  ViewSidebar as ViewSidebarIcon,
} from "@mui/icons-material";
import {
  Alert,
  AlertTitle,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Snackbar,
  Stack,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  SiteGroupOut,
  useWebApiV1DeleteClonedOrderMutation,
  useWebApiV1GetPatientQuery,
  useWebApiV1GetTenantPhysiciansQuery,
  useWebApiV1ListSiteGroupsQuery,
  useWebApiV1OrderQuery,
  useWebApiV1UpdateOrderMutation,
} from "@providers/hop-ord-server/api";
import { skipToken } from "@reduxjs/toolkit/query/react";
import { useCallback, useEffect, useRef, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import AdditionalInfo from "./components/AdditionalInfo/AdditionalInfo";
import DeleteOrderModal from "./components/DeleteOrderModal/DeleteOrderModal";
import DeleteSiteGroupModal from "./components/DeleteSiteGroupModal/DeleteSiteGroupModal";
import ProtocolPanel from "./components/ProtocolPanel/ProtocolPanel";
import RightDrawer from "./components/RightDrawer/RightDrawer";

import { useInterval } from "@utils";
import { useAppSelector } from "@hooks";
import PatientDetailsFormSkeleton from "@components/PatientDetailsForm/PatientDetailsFormSkeleton";
import SiteGroupPanelSkeleton from "@components/SiteGroupPanel/SiteGroupPanelSkeleton";

const DRAFT = "draft";

interface ActionIconProps {
  screenIsLarge: boolean;
  setDrawerOpen: (isOpen: boolean) => void;
  discardFunction: () => void;
  isReadOnly: boolean;
  isLoading: boolean;
}

const ActionIcons = ({
  setDrawerOpen,
  screenIsLarge,
  discardFunction,
  isReadOnly,
  isLoading,
}: ActionIconProps) => {
  if (isLoading) {
    return (
      <Skeleton
        data-testid="loading-action-icons"
        variant="circular"
        width={30}
        height={30}
      />
    );
  }
  return (
    <>
      {!isReadOnly && (
        <Tooltip title="Discard">
          <IconButton onClick={discardFunction}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      {!screenIsLarge && (
        <>
          <Tooltip title="Open sidebar">
            <IconButton
              onClick={() => {
                setDrawerOpen(true);
              }}
            >
              <ViewSidebarIcon />
            </IconButton>
          </Tooltip>
        </>
      )}
    </>
  );
};

const ContentsHeaderText = ({ text, id }: { text: string; id: string }) => (
  <Typography
    id={id}
    variant="h6"
    sx={{ scrollMarginTop: "18rem" }}
    paddingBottom={2}
  >
    {text}
  </Typography>
);

const OrderPage = () => {
  const theme = useTheme();
  const { orderId } = useParams();
  const [updateOrder] = useWebApiV1UpdateOrderMutation();
  const [deleteOrder] = useWebApiV1DeleteClonedOrderMutation();
  const navigate = useNavigate();
  const [deleteSiteGroupModalOpen, setDeleteSiteGroupModalOpen] =
    useState(false);
  const [deleteOrderModalOpen, setDeleteOrderModalOpen] = useState(false);
  const [selectedSiteGroup, setSelectedSiteGroup] =
    useState<SiteGroupOut | null>(null);
  const [submitCount, setSubmitCount] = useState(0);
  const [openToast, setOpenToast] = useState(false);
  const [toastComment, setToastComment] = useState({ title: "", comment: "" });
  const [showAdditionalInfo, setShowAdditionalInfo] = useState<boolean>(false);
  const siteGroupRefs = useRef<(SiteGroupPanelRefObject | null)[]>([]);
  const additionalInfoRef = useRef<HTMLInputElement | null>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isDataInSync, setIsDataInSync] = useState(false);
  const roles: Role[] = useAppSelector((state) => state?.auth?.roles);
  const [patientForm, setPatientForm] =
    useState<UseFormReturn<PatientInputTypes> | null>(null);
  const [siteGroupValidities, setSiteGroupValidities] = useState<{
    [key: number]: boolean;
  }>({});
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [siteGroupPanelLoading, setSiteGroupPanelLoading] = useState(false);

  const isDosimetrist = roles.includes(Role.DOSIMETRIST);
  const orderPageName = isDosimetrist ? "fulfillment" : "order";

  const handleSiteGroupLoadingChange = (isLoading: boolean) => {
    setSiteGroupPanelLoading(isLoading);
  };

  const handleReportedSiteGroupValidity = useCallback(
    (siteGroupId: number | string, isValid: boolean) => {
      setSiteGroupValidities((prev) => {
        const updated = { ...prev };
        updated[siteGroupId as number] = isValid;
        return updated;
      });
    },
    [],
  );
  const screenIsLarge = useMediaQuery(theme.breakpoints.up("lg"));

  const { data: orderData, error: orderError } = useWebApiV1OrderQuery({
    orderId: Number(orderId),
  });

  const { data: physicians } = useWebApiV1GetTenantPhysiciansQuery();

  // Get Patient Data
  const { data: patientData, error: patientError } = useWebApiV1GetPatientQuery(
    orderData?.patientId !== undefined && orderData?.patientId !== null
      ? { patientId: orderData.patientId }
      : skipToken,
  );

  // Get Site Groups
  const { data: siteGroupData, error: siteGroupError } =
    useWebApiV1ListSiteGroupsQuery({ orderId: Number(orderId) });
  const isReadOnly = orderData?.status !== DRAFT;

  const triggerToast = (title: string, comment: string): void => {
    setToastComment({ title: title, comment: comment });
    setOpenToast(true);
  };

  // Discard Order
  const discardOrder = async () => {
    if (isReadOnly) return;
    const parentId = orderData?.parentId;
    await deleteOrder({ orderId: Number(orderId) }).then(() => {
      // Using replace prevents the back button from navigating to the deleted order
      if (parentId) {
        navigate(`/${orderPageName}/${parentId}/tracker`, { replace: true });
      } else {
        navigate(`/${orderPageName}`, { replace: true });
      }
    });
  };

  const handleUpdateOrderField = useCallback(
    // Updates a single field in the order
    (field: string, value: string | number) => {
      updateOrder({
        orderId: Number(orderId),
        orderIn: { ...orderData, [field]: value },
      })
        .unwrap()
        .then((payload) => {
          if (field === "status" && payload.status === "submitted") {
            // If we're submitting the order, navigate to the tracker page
            setIsSubmitting(false);
            value === "submitted" &&
              navigate(`/${orderPageName}/${orderId}/tracker`);
          }
        });
    },
    [orderData, orderId, updateOrder, navigate],
  );

  const submitOrder = async () => {
    if (isReadOnly) return;
    const patientValid = await patientForm?.trigger();
    setSubmitCount(submitCount + 1);

    if (!patientValid) {
      triggerToast(
        "Invalid Patient Form",
        "Please check the patient form for errors",
      );
      return;
    }
    setIsSubmitting(true);
    setIsDataInSync(false);
  };

  useEffect(() => {
    if (siteGroupData && siteGroupData.length > 0) {
      setShowAdditionalInfo(true);
    }
    if (siteGroupData && siteGroupData.length === 0 && showAdditionalInfo) {
      setShowAdditionalInfo(false);
      handleUpdateOrderField("additionalInfo", "");
    }
  }, [siteGroupData, handleUpdateOrderField, showAdditionalInfo]);

  // Keep checking the tables to make sure they are in sync with backend
  useInterval(
    () => {
      const numberOfSiteGroups = siteGroupData?.length || 0;
      const siteGroupInSync = siteGroupRefs.current
        .map((ref, index) => {
          if (index >= numberOfSiteGroups) return true;
          return ref?.trigger();
        })
        .every((valid) => valid);
      // check if additional info is in sync only if the additional info is shown
      const isAdditionalInfoInSync =
        additionalInfoRef.current === null ||
        additionalInfoRef.current?.value === orderData?.additionalInfo ||
        (additionalInfoRef.current?.value === "" &&
          orderData?.additionalInfo === null);
      setIsDataInSync(siteGroupInSync && isAdditionalInfoInSync);
    },
    isSubmitting && !isDataInSync ? 1000 : null,
  );

  // Wait for the data sync to finish before submitting
  useEffect(() => {
    if (isSubmitting && isDataInSync) {
      if (!siteGroupData?.length) {
        triggerToast(
          "No Site Groups",
          "Please add at least one site group to the order",
        );
        setIsSubmitting(false);
        return;
      }

      if (Object.keys(siteGroupValidities).length !== siteGroupData?.length) {
        triggerToast(
          "Invalid Site Group",
          "Something went wrong when validating the site group",
        );
        setIsSubmitting(false);
        return;
      }

      if (Object.values(siteGroupValidities).includes(false)) {
        triggerToast(
          "Invalid Site Group",
          "Please check the site group for errors",
        );
        setIsSubmitting(false);
        return;
      }

      if (!orderData?.treatingRo) {
        triggerToast(
          "No physician selected",
          "Please select a physician for the order",
        );
        setIsSubmitting(false);
        return;
      }

      handleUpdateOrderField("status", "submitted");
    }
  }, [
    isSubmitting,
    isDataInSync,
    siteGroupData,
    siteGroupValidities,
    handleUpdateOrderField,
    navigate,
    roles,
    orderData?.treatingRo,
  ]);

  // Open Delete Site Group Modal
  const openDeleteSiteGroupModal = (siteGroup: SiteGroupOut) => {
    if (isReadOnly) return;
    setSelectedSiteGroup(siteGroup);
    setDeleteSiteGroupModalOpen(true);
  };

  // show a blank screen, a bad query will trigger the global error alert
  if (orderError || patientError || siteGroupError) return null;

  return (
    <>
      <Stack spacing={3} paddingBottom={2}>
        <OrderHeader
          title="New Order"
          orderData={orderData}
          right={
            <ActionIcons
              screenIsLarge={screenIsLarge}
              discardFunction={() => {
                setDeleteOrderModalOpen(true);
              }}
              setDrawerOpen={setDrawerOpen}
              isReadOnly={isReadOnly}
              isLoading={!orderData}
            />
          }
        >
          <ActionBar
            text="Submit Order"
            isLoading={!orderData}
            actions={
              <Stack direction="row" gap={1}>
                <Button
                  variant="outlined"
                  onClick={() => {
                    navigate("/");
                  }}
                >
                  Save & Exit
                </Button>
                {!isReadOnly && (
                  <Button
                    busy={isSubmitting}
                    variant="contained"
                    onClick={submitOrder}
                    data-testid="submit-order-button"
                  >
                    Submit
                  </Button>
                )}
              </Stack>
            }
          />
        </OrderHeader>

        {/* Physician selection */}
        {isDosimetrist && (
          <Stack>
            <ContentsHeaderText text="Physician" id="physician" />
            <FormControl sx={{ m: 1, minWidth: 120 }} required>
              <InputLabel id="requested-physician-label">
                Requested Physician
              </InputLabel>
              <Select
                fullWidth
                labelId="requested-physician-label"
                label="Requested Physician"
                disabled={isReadOnly}
                data-testid="requested-physician"
                value={orderData?.treatingRoId?.toString() || ""}
                onChange={(event: SelectChangeEvent) => {
                  handleUpdateOrderField("treatingRoId", event.target.value);
                }}
              >
                {physicians?.map((physician) => (
                  <MenuItem key={physician.id} value={physician.id}>
                    {physician.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Stack>
        )}

        {/* Patient Details */}
        <Stack>
          <ContentsHeaderText text="Patient Details" id="patient-details" />
          {!orderData || !patientData ? (
            <PatientDetailsFormSkeleton />
          ) : (
            <PatientDetailsForm
              setForm={setPatientForm}
              patientId={orderData?.patientId ?? -1}
              orderId={Number(orderId)}
              orderData={orderData ?? {}}
              patientData={patientData ?? {}}
              isReadOnly={isReadOnly}
            />
          )}
        </Stack>

        {/* Protocol Panel */}
        <Stack>
          <ContentsHeaderText text="Planning Protocol" id="planning-protocol" />
          <ProtocolPanel
            isReadOnly={isReadOnly}
            orderId={Number.parseInt(orderId ?? "")}
          />
        </Stack>

        {/* Site Groups */}
        {!siteGroupData ? (
          <SiteGroupPanelSkeleton />
        ) : (
          siteGroupData?.map((siteGroup, index) => (
            <SiteGroupPanel
              ref={(ref) => (siteGroupRefs.current[index] = ref)}
              index={index}
              key={siteGroup.id}
              siteGroup={siteGroup}
              isReadOnly={isReadOnly}
              submitCount={submitCount}
              reportValidity={handleReportedSiteGroupValidity}
              onDeleteCallback={openDeleteSiteGroupModal}
              onLoadingChange={handleSiteGroupLoadingChange}
              doseUnit={orderData?.doseUnit ?? ""}
              fractionUnit={orderData?.fractionUnit ?? ""}
            />
          ))
        )}

        <AutoScroll submitCount={submitCount} />

        {/* Additional info - only show if there is at least one site group added */}
        {!siteGroupData || siteGroupPanelLoading ? (
          <Stack data-testid="loading-additional-info">
            <Skeleton variant="rounded" height={56} />
          </Stack>
        ) : (
          showAdditionalInfo && (
            <AdditionalInfo
              isReadOnly={isReadOnly}
              currentValue={orderData?.additionalInfo || ""}
              onBlur={handleUpdateOrderField}
              inputRef={additionalInfoRef}
            />
          )
        )}
      </Stack>

      <DeleteSiteGroupModal
        open={deleteSiteGroupModalOpen}
        setOpen={setDeleteSiteGroupModalOpen}
        selectedSiteGroup={selectedSiteGroup}
        orderId={Number(orderId)}
        onSiteGroupDeletedCallback={(siteGroupId: number) => {
          // Remove the site group from the validities array
          setSiteGroupValidities((prev) =>
            Object.fromEntries(
              Object.entries(prev).filter(
                ([k]) => k !== siteGroupId.toString(),
              ),
            ),
          );
        }}
      />

      <DeleteOrderModal
        open={deleteOrderModalOpen}
        setOpen={setDeleteOrderModalOpen}
        deleteOrder={discardOrder}
      />

      <RightDrawer
        permanent={screenIsLarge}
        addedSiteGroups={siteGroupData ?? []}
        onDeleteSiteGroup={openDeleteSiteGroupModal}
        setOpen={setDrawerOpen}
        open={drawerOpen}
        isLoading={!orderData}
      />

      <Snackbar
        open={openToast}
        onClose={() => setOpenToast(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
      >
        <Alert severity="error">
          <AlertTitle>{toastComment.title}</AlertTitle>
          {toastComment.comment}
        </Alert>
      </Snackbar>
    </>
  );
};

export default OrderPage;
