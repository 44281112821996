import { Stack, Typography, Divider } from "@mui/material";
import dayjs from "dayjs";
import {
  SiteGroupOut,
  useWebApiV1ListSitesQuery,
  useWebApiV1ListSiteGroupsQuery,
  useWebApiV1OrderQuery,
  useWebApiV1ListAttachmentsQuery,
  FileAttachmentOut,
} from "@providers/hop-ord-server/api";
import { toLocaleDateTimeString } from "@utils";
import {
  DosePrescription,
  SiteGroupName,
} from "@components/SiteTable/SiteTable.types";
import { OrderDetailsRow } from "@pages/Order/components/OrderDetails/OrderDetails";
import { siteGroupName } from "@components/SiteGroupPanel/SiteGroupPanel";
import { Scorecard } from "@components";
import { getTimeZoneShort } from "@utils";
import { theme } from "@theme";
import PdfOrderTable from "./PdfOrderTable";

interface Props {
  orderId: number;
}

interface SiteTableProps {
  siteGroupId: number;
  doseUnit: string;
  fractionUnit: string;
}

const SiteTable = ({
  siteGroupId,
  doseUnit,
  fractionUnit,
}: SiteTableProps): JSX.Element => {
  const { data, isLoading } = useWebApiV1ListSitesQuery({
    siteGroupId: siteGroupId.toString(),
  });

  if (isLoading) return <></>;

  return (
    <Stack overflow="auto" width={1}>
      <Typography variant="h6">
        Dose Prescription:{" "}
        {siteGroupName(data as SiteGroupName[], doseUnit, fractionUnit)}
      </Typography>
      <PdfOrderTable
        rows={data as DosePrescription[]}
        doseUnit={doseUnit}
        fractionUnit={fractionUnit}
      />
    </Stack>
  );
};

const PdfOrder = ({ orderId }: Props): JSX.Element => {
  const { data: siteGroupData, isLoading: siteGroupLoading } =
    useWebApiV1ListSiteGroupsQuery({ orderId: Number(orderId) });

  const { data: orderData, isLoading: orderIsLoading } = useWebApiV1OrderQuery({
    orderId: Number(orderId),
  });

  const { data: uploadedFileData } = useWebApiV1ListAttachmentsQuery({
    orderId: Number(orderId),
  });

  const logoURL = new URL("@assets/pdf_logo.png", import.meta.url).href;

  if (siteGroupLoading || orderIsLoading) return <></>;

  const formatApprovedText = (): string => {
    if (!orderData?.approvedDatetime) {
      return "ORDER NOT APPROVED";
    }

    const approvedDateTime = toLocaleDateTimeString(
      orderData?.approvedDatetime || "",
    );
    const approvedTimeZone = getTimeZoneShort(
      dayjs(orderData?.approvedDatetime).toDate(),
    );

    const onBehalfOf =
      orderData.approvedOnBehalfName !== orderData.treatingRo
        ? orderData.approvedOnBehalfName + " on behalf of "
        : "";

    return `Reviewed and approved by: ${onBehalfOf}Dr ${orderData?.treatingRo} on ${approvedDateTime} ${approvedTimeZone}`;
  };

  const firstScorecard = uploadedFileData?.scorecards?.[0];
  // Throw error if doseUnit and fractionUnit are not present
  if (!orderData?.doseUnit || !orderData?.fractionUnit) {
    throw new Error("Dose unit or fraction unit is not present in order data");
  }
  return (
    <Stack data-testid="drawer-details" height={1} width={1} margin={2}>
      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        marginRight={2}
      >
        <Typography variant="h4">Plan Review Report</Typography>
        <img src={logoURL} style={{ height: "60px" }} />
      </Stack>
      <OrderDetailsRow label="Name:" value={orderData?.patientName || ""} />
      <OrderDetailsRow
        label="Date of birth:"
        value={toLocaleDateTimeString(orderData?.patientDob || "", "date")}
      />
      <OrderDetailsRow
        label="Patient ID:"
        value={orderData?.patientMrn || "-"}
      />
      <OrderDetailsRow
        label="Treating location/unit:"
        value={orderData?.treatingDepartment || "-"}
      />
      <OrderDetailsRow
        label="Printed:"
        value={`${toLocaleDateTimeString(
          new Date(Date.now()),
        )} ${getTimeZoneShort(new Date())}`}
      />
      <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
      <Typography variant="h4">
        Plan Name: {firstScorecard?.fileName.replace(".json", "") || "N/A"}
      </Typography>
      <Typography variant="body1">{formatApprovedText()}</Typography>
      <Typography variant="body1">
        TPS approval date: {firstScorecard?.planLockedTimestamp || "N/A"}
      </Typography>
      <Typography variant="body1">Lumonus ID: {orderData?.id}</Typography>
      {siteGroupData?.map((siteGroup: SiteGroupOut) => (
        <Stack marginTop={2} key={siteGroup?.id}>
          <SiteTable
            siteGroupId={siteGroup?.id as number}
            doseUnit={orderData?.doseUnit || ""}
            fractionUnit={orderData?.fractionUnit || ""}
          />
        </Stack>
      ))}
      {uploadedFileData?.scorecards?.map(
        (scorecard: FileAttachmentOut, index: number) => {
          const fileNameWithoutExtension = scorecard?.fileName
            .split(".")
            .slice(0, -1)
            .join(".");
          return (
            <Stack marginTop={3} key={`pdf-scorecard-${index}`}>
              <Typography variant="h6">
                Scorecard: {fileNameWithoutExtension}
              </Typography>
              <Scorecard data={scorecard?.scorecardObj} />
              <Typography
                padding={1}
                variant="body2"
                color={theme.palette.text.secondary}
              >
                {`Plan locked: ${
                  scorecard.planLockedTimestamp || "Not recorded"
                }`}
              </Typography>
            </Stack>
          );
        },
      )}
    </Stack>
  );
};

export default PdfOrder;
