import { api } from "./baseapi";
export const addTagTypes = [
  "Carepath Data",
  "Patient",
  "Order",
  "Delete Order",
  "External Lumonus AI",
  "users",
  "FeatureSwitches",
  "SiteGroup",
  "Site",
  "OrganAtRisk",
  "DoseAndObjectives",
  "Scorecard",
  "WalkthroughAttachment",
  "Comment",
  "CommentAttachment",
  "Notifications",
  "Demo Data",
  "Rainbow Data",
  "Appointments",
  "Device",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      protocolApiV1ListProtocols: build.query<
        ProtocolApiV1ListProtocolsApiResponse,
        ProtocolApiV1ListProtocolsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/protocols`,
          params: {
            order_by: queryArg.orderBy,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["Carepath Data"],
      }),
      protocolApiV1ListData: build.query<
        ProtocolApiV1ListDataApiResponse,
        ProtocolApiV1ListDataApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/listdata`,
          params: {
            categories: queryArg.categories,
          },
        }),
        providesTags: ["Carepath Data"],
      }),
      protocolApiV1TreatmentSiteData: build.query<
        ProtocolApiV1TreatmentSiteDataApiResponse,
        ProtocolApiV1TreatmentSiteDataApiArg
      >({
        query: () => ({ url: `/ord/api/v1/treatment-site` }),
        providesTags: ["Carepath Data"],
      }),
      protocolApiV1OrganData: build.query<
        ProtocolApiV1OrganDataApiResponse,
        ProtocolApiV1OrganDataApiArg
      >({
        query: () => ({ url: `/ord/api/v1/organ` }),
        providesTags: ["Carepath Data"],
      }),
      webApiV1GetPatient: build.query<
        WebApiV1GetPatientApiResponse,
        WebApiV1GetPatientApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/patients/${queryArg.patientId}`,
        }),
        providesTags: ["Patient"],
      }),
      webApiV1UpdatePatient: build.mutation<
        WebApiV1UpdatePatientApiResponse,
        WebApiV1UpdatePatientApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/patients/${queryArg.patientId}`,
          method: "PATCH",
          body: queryArg.patientIn,
        }),
        invalidatesTags: ["Patient"],
      }),
      webApiV1Order: build.query<WebApiV1OrderApiResponse, WebApiV1OrderApiArg>(
        {
          query: (queryArg) => ({
            url: `/ord/api/v1/orders/${queryArg.orderId}`,
          }),
          providesTags: ["Order"],
        },
      ),
      webApiV1UpdateOrder: build.mutation<
        WebApiV1UpdateOrderApiResponse,
        WebApiV1UpdateOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}`,
          method: "PATCH",
          body: queryArg.orderIn,
        }),
        invalidatesTags: ["Order"],
      }),
      webApiV1DeleteClonedOrder: build.mutation<
        WebApiV1DeleteClonedOrderApiResponse,
        WebApiV1DeleteClonedOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Delete Order"],
      }),
      webApiV1CreateOrder: build.mutation<
        WebApiV1CreateOrderApiResponse,
        WebApiV1CreateOrderApiArg
      >({
        query: () => ({ url: `/ord/api/v1/orders`, method: "POST" }),
        invalidatesTags: ["Order"],
      }),
      webApiV1ListOrders: build.query<
        WebApiV1ListOrdersApiResponse,
        WebApiV1ListOrdersApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders`,
          params: {
            ids: queryArg.ids,
            recent: queryArg.recent,
            filter: queryArg.filter,
            limit: queryArg.limit,
            offset: queryArg.offset,
          },
        }),
        providesTags: ["Order", "Delete Order"],
      }),
      webApiV1CloneOrder: build.mutation<
        WebApiV1CloneOrderApiResponse,
        WebApiV1CloneOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/edit`,
          method: "POST",
        }),
        invalidatesTags: ["Order"],
      }),
      webApiV1RejectOrder: build.mutation<
        WebApiV1RejectOrderApiResponse,
        WebApiV1RejectOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/reject`,
          method: "PATCH",
          body: queryArg.rejectOrderIn,
        }),
        invalidatesTags: ["Order"],
      }),
      webApiV1CreateFhirOrder: build.mutation<
        WebApiV1CreateFhirOrderApiResponse,
        WebApiV1CreateFhirOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/fhir/order`,
          method: "POST",
          body: queryArg.lumonusAiOrderIn,
        }),
        invalidatesTags: ["External Lumonus AI"],
      }),
      webApiV1OrderStatusChange: build.query<
        WebApiV1OrderStatusChangeApiResponse,
        WebApiV1OrderStatusChangeApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/status`,
          params: {
            status_name: queryArg.statusName,
          },
        }),
        providesTags: ["Order"],
      }),
      webApiV1GetTenantPhysicians: build.query<
        WebApiV1GetTenantPhysiciansApiResponse,
        WebApiV1GetTenantPhysiciansApiArg
      >({
        query: () => ({ url: `/ord/api/v1/users/physicians` }),
        providesTags: ["users"],
      }),
      webApiV1GetUserFeatureSwitches: build.query<
        WebApiV1GetUserFeatureSwitchesApiResponse,
        WebApiV1GetUserFeatureSwitchesApiArg
      >({
        query: () => ({ url: `/ord/api/v1/users/features` }),
        providesTags: ["FeatureSwitches"],
      }),
      webApiV1GetFeatureSwitchesForOrder: build.query<
        WebApiV1GetFeatureSwitchesForOrderApiResponse,
        WebApiV1GetFeatureSwitchesForOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/users/features/order/${queryArg.orderId}`,
        }),
        providesTags: ["FeatureSwitches"],
      }),
      webApiV1ListSiteGroups: build.query<
        WebApiV1ListSiteGroupsApiResponse,
        WebApiV1ListSiteGroupsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/site-groups`,
        }),
        providesTags: ["SiteGroup", "Order"],
      }),
      webApiV1CreateSiteGroup: build.mutation<
        WebApiV1CreateSiteGroupApiResponse,
        WebApiV1CreateSiteGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/site-groups`,
          method: "POST",
          params: {
            protocol_id: queryArg.protocolId,
            manual_site_name: queryArg.manualSiteName,
            manual_site_specify: queryArg.manualSiteSpecify,
          },
        }),
        invalidatesTags: ["SiteGroup", "Order"],
      }),
      webApiV1UpdateSiteGroup: build.mutation<
        WebApiV1UpdateSiteGroupApiResponse,
        WebApiV1UpdateSiteGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/site-groups/${queryArg.siteGroupId}`,
          method: "PATCH",
          body: queryArg.siteGroupIn,
        }),
        invalidatesTags: ["SiteGroup", "Order"],
      }),
      webApiV1DeleteSiteGroup: build.mutation<
        WebApiV1DeleteSiteGroupApiResponse,
        WebApiV1DeleteSiteGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/site-groups/${queryArg.siteGroupId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["SiteGroup", "Order"],
      }),
      webApiV1ListSites: build.query<
        WebApiV1ListSitesApiResponse,
        WebApiV1ListSitesApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/site-groups/${queryArg.siteGroupId}/sites`,
        }),
        providesTags: ["Site"],
      }),
      webApiV1CreateSite: build.mutation<
        WebApiV1CreateSiteApiResponse,
        WebApiV1CreateSiteApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/site-groups/${queryArg.siteGroupId}/sites`,
          method: "POST",
          params: {
            manual_site_name: queryArg.manualSiteName,
            manual_site_specify: queryArg.manualSiteSpecify,
          },
        }),
        invalidatesTags: ["Site", "SiteGroup", "Order"],
      }),
      webApiV1UpdateSite: build.mutation<
        WebApiV1UpdateSiteApiResponse,
        WebApiV1UpdateSiteApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/sites/${queryArg.siteId}`,
          method: "PUT",
          body: queryArg.siteOut,
        }),
        invalidatesTags: ["Site", "SiteGroup", "Order"],
      }),
      webApiV1DeleteSite: build.mutation<
        WebApiV1DeleteSiteApiResponse,
        WebApiV1DeleteSiteApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/sites/${queryArg.siteId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Site", "SiteGroup", "Order"],
      }),
      webApiV1ListOars: build.query<
        WebApiV1ListOarsApiResponse,
        WebApiV1ListOarsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/site-groups/${queryArg.siteGroupId}/oars`,
        }),
        providesTags: ["OrganAtRisk"],
      }),
      webApiV1CreateOar: build.mutation<
        WebApiV1CreateOarApiResponse,
        WebApiV1CreateOarApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/site-groups/${queryArg.siteGroupId}/oars`,
          method: "POST",
        }),
        invalidatesTags: ["OrganAtRisk"],
      }),
      webApiV1UpdateOar: build.mutation<
        WebApiV1UpdateOarApiResponse,
        WebApiV1UpdateOarApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/oars/${queryArg.oarId}`,
          method: "PUT",
          body: queryArg.oarsOut,
        }),
        invalidatesTags: ["OrganAtRisk"],
      }),
      webApiV1DeleteOar: build.mutation<
        WebApiV1DeleteOarApiResponse,
        WebApiV1DeleteOarApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/oars/${queryArg.oarId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["OrganAtRisk"],
      }),
      webApiV1ListTargetObjectives: build.query<
        WebApiV1ListTargetObjectivesApiResponse,
        WebApiV1ListTargetObjectivesApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/sites/${queryArg.siteId}/target-objectives`,
        }),
        providesTags: ["DoseAndObjectives"],
      }),
      webApiV1UpdateTargetObjective: build.mutation<
        WebApiV1UpdateTargetObjectiveApiResponse,
        WebApiV1UpdateTargetObjectiveApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/sites/${queryArg.siteId}/target-objectives/${queryArg.targetObjectiveId}`,
          method: "PUT",
          body: queryArg.targetObjectiveIn,
        }),
        invalidatesTags: ["DoseAndObjectives"],
      }),
      webApiV1ListAttachments: build.query<
        WebApiV1ListAttachmentsApiResponse,
        WebApiV1ListAttachmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/attachments`,
        }),
        providesTags: ["Scorecard"],
      }),
      webApiV1CreateAttachment: build.mutation<
        WebApiV1CreateAttachmentApiResponse,
        WebApiV1CreateAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/attachments`,
          method: "POST",
          body: queryArg.scorecardAttachmentIn,
        }),
        invalidatesTags: ["Scorecard"],
      }),
      webApiV1DeleteAttachment: build.mutation<
        WebApiV1DeleteAttachmentApiResponse,
        WebApiV1DeleteAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/attachments/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Scorecard"],
      }),
      webApiV1ReadAttachment: build.mutation<
        WebApiV1ReadAttachmentApiResponse,
        WebApiV1ReadAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/attachments/${queryArg.attachmentId}`,
          method: "PUT",
        }),
        invalidatesTags: ["Scorecard"],
      }),
      webApiV1ListWalkthroughAttachments: build.query<
        WebApiV1ListWalkthroughAttachmentsApiResponse,
        WebApiV1ListWalkthroughAttachmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/walkthrough-attachments`,
        }),
        providesTags: ["WalkthroughAttachment"],
      }),
      webApiV1CreateWalkthroughAttachmentUploads: build.mutation<
        WebApiV1CreateWalkthroughAttachmentUploadsApiResponse,
        WebApiV1CreateWalkthroughAttachmentUploadsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/walkthrough-attachments`,
          method: "POST",
          body: queryArg.fileNames,
        }),
        invalidatesTags: ["WalkthroughAttachment"],
      }),
      webApiV1DeleteWalkthroughAttachment: build.mutation<
        WebApiV1DeleteWalkthroughAttachmentApiResponse,
        WebApiV1DeleteWalkthroughAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/walkthrough-attachments/${queryArg.attachmentId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["WalkthroughAttachment"],
      }),
      webApiV1UpdateWalkthroughAttachment: build.mutation<
        WebApiV1UpdateWalkthroughAttachmentApiResponse,
        WebApiV1UpdateWalkthroughAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/orders/${queryArg.orderId}/walkthrough-attachments/${queryArg.fileId}/`,
          method: "PATCH",
          params: {
            upload_complete: queryArg.uploadComplete,
            sent_for_review: queryArg.sentForReview,
            read: queryArg.read,
          },
        }),
        invalidatesTags: ["WalkthroughAttachment"],
      }),
      commentsApiV1GetCommentForOrder: build.query<
        CommentsApiV1GetCommentForOrderApiResponse,
        CommentsApiV1GetCommentForOrderApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order-groups/${queryArg.orderGroupId}/comments`,
        }),
        providesTags: ["Comment"],
      }),
      commentsApiV1PostComment: build.mutation<
        CommentsApiV1PostCommentApiResponse,
        CommentsApiV1PostCommentApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order-groups/${queryArg.orderGroupId}/comments`,
          method: "POST",
          body: queryArg.commentIn,
        }),
        invalidatesTags: ["Comment"],
      }),
      commentsApiV1CreateAttachment: build.mutation<
        CommentsApiV1CreateAttachmentApiResponse,
        CommentsApiV1CreateAttachmentApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order-groups/${queryArg.orderGroupId}/attachments`,
          method: "POST",
          body: queryArg.attachmentIn,
        }),
        invalidatesTags: ["Comment", "CommentAttachment"],
      }),
      commentsApiV1GetUnreadCommentCount: build.query<
        CommentsApiV1GetUnreadCommentCountApiResponse,
        CommentsApiV1GetUnreadCommentCountApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/unread-comment-count/${queryArg.orderGroupId}`,
        }),
        providesTags: ["Comment"],
      }),
      commentsApiV1GetAllUnreadComments: build.query<
        CommentsApiV1GetAllUnreadCommentsApiResponse,
        CommentsApiV1GetAllUnreadCommentsApiArg
      >({
        query: () => ({ url: `/ord/api/v1/unread-comment-count/` }),
        providesTags: ["Comment"],
      }),
      commentsApiV1SetCommentsRead: build.mutation<
        CommentsApiV1SetCommentsReadApiResponse,
        CommentsApiV1SetCommentsReadApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/set-comments-read/${queryArg.orderGroupId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["Comment", "Notifications"],
      }),
      webApiV1UpdateUserActivityLastViewed: build.mutation<
        WebApiV1UpdateUserActivityLastViewedApiResponse,
        WebApiV1UpdateUserActivityLastViewedApiArg
      >({
        query: () => ({
          url: `/ord/api/v1/user_activity/notifications_last_viewed`,
          method: "PUT",
        }),
        invalidatesTags: ["Notifications"],
      }),
      webApiV1ResetDemoData: build.mutation<
        WebApiV1ResetDemoDataApiResponse,
        WebApiV1ResetDemoDataApiArg
      >({
        query: () => ({ url: `/ord/api/v1/reset_demo_data`, method: "POST" }),
        invalidatesTags: ["Demo Data"],
      }),
      automationIntegrationApiV1GetStructures: build.query<
        AutomationIntegrationApiV1GetStructuresApiResponse,
        AutomationIntegrationApiV1GetStructuresApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order/${queryArg.orderId}/structures`,
        }),
        providesTags: ["Rainbow Data"],
      }),
      automationIntegrationApiV1ListTemplates: build.query<
        AutomationIntegrationApiV1ListTemplatesApiResponse,
        AutomationIntegrationApiV1ListTemplatesApiArg
      >({
        query: () => ({ url: `/ord/api/v1/eclipse/templates` }),
        providesTags: ["Rainbow Data"],
      }),
      automationIntegrationApiV1ListTemplateDetails: build.query<
        AutomationIntegrationApiV1ListTemplateDetailsApiResponse,
        AutomationIntegrationApiV1ListTemplateDetailsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/eclipse/templates/${queryArg.templateName}`,
        }),
        providesTags: ["Rainbow Data"],
      }),
      automationIntegrationApiV1GetOrderEvents: build.query<
        AutomationIntegrationApiV1GetOrderEventsApiResponse,
        AutomationIntegrationApiV1GetOrderEventsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order/${queryArg.orderId}/events`,
        }),
        providesTags: ["Rainbow Data"],
      }),
      automationIntegrationApiV1GetOrderTreatmentPlanResult: build.query<
        AutomationIntegrationApiV1GetOrderTreatmentPlanResultApiResponse,
        AutomationIntegrationApiV1GetOrderTreatmentPlanResultApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order/${queryArg.orderId}/plan-response`,
        }),
        providesTags: ["Rainbow Data"],
      }),
      automationIntegrationApiV1SendRequest: build.mutation<
        AutomationIntegrationApiV1SendRequestApiResponse,
        AutomationIntegrationApiV1SendRequestApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order/${queryArg.orderId}/get-structures`,
          method: "POST",
        }),
        invalidatesTags: ["Rainbow Data"],
      }),
      automationIntegrationApiV1SendPlanGeneration: build.mutation<
        AutomationIntegrationApiV1SendPlanGenerationApiResponse,
        AutomationIntegrationApiV1SendPlanGenerationApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order/${queryArg.orderId}/send-plan-generation`,
          method: "POST",
          body: queryArg.structureMapIn,
        }),
        invalidatesTags: ["Rainbow Data"],
      }),
      automationIntegrationApiV1CreateMockStatus: build.mutation<
        AutomationIntegrationApiV1CreateMockStatusApiResponse,
        AutomationIntegrationApiV1CreateMockStatusApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order/${queryArg.orderId}/create-mock-status`,
          method: "POST",
          params: {
            status_type: queryArg.statusType,
            event_details: queryArg.eventDetails,
          },
        }),
        invalidatesTags: ["Rainbow Data"],
      }),
      automationIntegrationApiV1MockImportedCtRtStructResponse: build.mutation<
        AutomationIntegrationApiV1MockImportedCtRtStructResponseApiResponse,
        AutomationIntegrationApiV1MockImportedCtRtStructResponseApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order/${queryArg.orderId}/mock-imported-ct-rt-struct-response`,
          method: "POST",
          params: {
            response_type: queryArg.responseType,
          },
        }),
        invalidatesTags: ["Rainbow Data", "Order"],
      }),
      automationIntegrationApiV1MockPendingCtRtStructResponse: build.mutation<
        AutomationIntegrationApiV1MockPendingCtRtStructResponseApiResponse,
        AutomationIntegrationApiV1MockPendingCtRtStructResponseApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/order/${queryArg.orderId}/mock-pending-ct-rt-struct-response`,
          method: "POST",
          params: {
            response_type: queryArg.responseType,
          },
        }),
        invalidatesTags: ["Rainbow Data", "Order"],
      }),
      webApiV1ListAppointments: build.query<
        WebApiV1ListAppointmentsApiResponse,
        WebApiV1ListAppointmentsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/lumonus-ai/appointments`,
          params: {
            start_date: queryArg.startDate,
            end_date: queryArg.endDate,
            user_filter: queryArg.userFilter,
          },
        }),
        providesTags: ["Appointments"],
      }),
      notificationsApiV1RegisterUserDevice: build.mutation<
        NotificationsApiV1RegisterUserDeviceApiResponse,
        NotificationsApiV1RegisterUserDeviceApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/notifications/register-device`,
          method: "POST",
          body: queryArg.registerDevice,
        }),
        invalidatesTags: ["Device"],
      }),
      notificationsApiV1IsDeviceRegistered: build.query<
        NotificationsApiV1IsDeviceRegisteredApiResponse,
        NotificationsApiV1IsDeviceRegisteredApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/notifications/register-device/${queryArg.deviceToken}`,
        }),
        providesTags: ["Device"],
      }),
      notificationsApiV1GetActivityCount: build.query<
        NotificationsApiV1GetActivityCountApiResponse,
        NotificationsApiV1GetActivityCountApiArg
      >({
        query: () => ({ url: `/ord/api/v1/notifications/activity/` }),
        providesTags: ["Notifications"],
      }),
      notificationsApiV1GetNotifications: build.query<
        NotificationsApiV1GetNotificationsApiResponse,
        NotificationsApiV1GetNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/notifications/device/${queryArg.deviceToken}`,
        }),
        providesTags: ["Notifications"],
      }),
      notificationsApiV1UpdateNotificationViewed: build.mutation<
        NotificationsApiV1UpdateNotificationViewedApiResponse,
        NotificationsApiV1UpdateNotificationViewedApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/notifications/${queryArg.notificationId}`,
          method: "PATCH",
        }),
        invalidatesTags: ["Notifications"],
      }),
      notificationsApiV1UpdateNotifications: build.mutation<
        NotificationsApiV1UpdateNotificationsApiResponse,
        NotificationsApiV1UpdateNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/ord/api/v1/notifications/order-groups/${queryArg.orderGroupId}`,
          method: "PATCH",
          body: queryArg.notificationIn,
        }),
        invalidatesTags: ["Notifications"],
      }),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as orderApi };
export type ProtocolApiV1ListProtocolsApiResponse =
  /** status 200 OK */ PagedProtocolInfoOut;
export type ProtocolApiV1ListProtocolsApiArg = {
  orderBy?: string;
  limit?: number;
  offset?: number;
};
export type ProtocolApiV1ListDataApiResponse =
  /** status 200 OK */ ListDataOptionSerialised[];
export type ProtocolApiV1ListDataApiArg = {
  categories?: string[];
};
export type ProtocolApiV1TreatmentSiteDataApiResponse =
  /** status 200 OK */ TreatmentSiteSerialised[];
export type ProtocolApiV1TreatmentSiteDataApiArg = void;
export type ProtocolApiV1OrganDataApiResponse =
  /** status 200 OK */ OrganSerialised[];
export type ProtocolApiV1OrganDataApiArg = void;
export type WebApiV1GetPatientApiResponse = /** status 200 OK */ PatientOut;
export type WebApiV1GetPatientApiArg = {
  patientId: number;
};
export type WebApiV1UpdatePatientApiResponse = /** status 200 OK */ PatientOut;
export type WebApiV1UpdatePatientApiArg = {
  patientId: number;
  patientIn: PatientIn;
};
export type WebApiV1OrderApiResponse = /** status 200 OK */ OrderOut;
export type WebApiV1OrderApiArg = {
  orderId: number;
};
export type WebApiV1UpdateOrderApiResponse = /** status 200 OK */ OrderOut;
export type WebApiV1UpdateOrderApiArg = {
  orderId: number;
  orderIn: OrderIn;
};
export type WebApiV1DeleteClonedOrderApiResponse = /** status 200 OK */ number;
export type WebApiV1DeleteClonedOrderApiArg = {
  orderId: number;
};
export type WebApiV1CreateOrderApiResponse = /** status 200 OK */ number;
export type WebApiV1CreateOrderApiArg = void;
export type WebApiV1ListOrdersApiResponse = /** status 200 OK */ PagedOrderOut;
export type WebApiV1ListOrdersApiArg = {
  ids?: string | null;
  recent?: boolean;
  filter?: "all" | "action";
  limit?: number;
  offset?: number;
};
export type WebApiV1CloneOrderApiResponse = /** status 200 OK */ number;
export type WebApiV1CloneOrderApiArg = {
  orderId: number;
};
export type WebApiV1RejectOrderApiResponse = /** status 200 OK */ OrderOut;
export type WebApiV1RejectOrderApiArg = {
  orderId: number;
  rejectOrderIn: RejectOrderIn;
};
export type WebApiV1CreateFhirOrderApiResponse =
  /** status 200 OK */ LumonusAiOrderOut;
export type WebApiV1CreateFhirOrderApiArg = {
  lumonusAiOrderIn: LumonusAiOrderIn;
};
export type WebApiV1OrderStatusChangeApiResponse =
  /** status 200 OK */ StatusChangeOut | null;
export type WebApiV1OrderStatusChangeApiArg = {
  orderId: number;
  statusName: string;
};
export type WebApiV1GetTenantPhysiciansApiResponse = /** status 200 OK */ any[];
export type WebApiV1GetTenantPhysiciansApiArg = void;
export type WebApiV1GetUserFeatureSwitchesApiResponse =
  /** status 200 OK */ any[];
export type WebApiV1GetUserFeatureSwitchesApiArg = void;
export type WebApiV1GetFeatureSwitchesForOrderApiResponse =
  /** status 200 OK */ any[];
export type WebApiV1GetFeatureSwitchesForOrderApiArg = {
  orderId: number;
};
export type WebApiV1ListSiteGroupsApiResponse =
  /** status 200 OK */ SiteGroupOut[];
export type WebApiV1ListSiteGroupsApiArg = {
  orderId: number;
};
export type WebApiV1CreateSiteGroupApiResponse =
  /** status 200 OK */ SiteGroupOut[];
export type WebApiV1CreateSiteGroupApiArg = {
  orderId: number;
  protocolId?: number | null;
  manualSiteName?: string | null;
  manualSiteSpecify?: string | null;
};
export type WebApiV1UpdateSiteGroupApiResponse =
  /** status 200 OK */ SiteGroupOut;
export type WebApiV1UpdateSiteGroupApiArg = {
  siteGroupId: number;
  siteGroupIn: SiteGroupIn;
};
export type WebApiV1DeleteSiteGroupApiResponse = unknown;
export type WebApiV1DeleteSiteGroupApiArg = {
  siteGroupId: number;
};
export type WebApiV1ListSitesApiResponse = /** status 200 OK */ SiteOut[];
export type WebApiV1ListSitesApiArg = {
  siteGroupId: string;
};
export type WebApiV1CreateSiteApiResponse = /** status 200 OK */ SiteOut;
export type WebApiV1CreateSiteApiArg = {
  siteGroupId: number;
  manualSiteName?: string | null;
  manualSiteSpecify?: string | null;
};
export type WebApiV1UpdateSiteApiResponse = /** status 200 OK */ SiteOut;
export type WebApiV1UpdateSiteApiArg = {
  siteId: number;
  siteOut: SiteOut;
};
export type WebApiV1DeleteSiteApiResponse = unknown;
export type WebApiV1DeleteSiteApiArg = {
  siteId: number;
};
export type WebApiV1ListOarsApiResponse = /** status 200 OK */ OarsOut[];
export type WebApiV1ListOarsApiArg = {
  siteGroupId: string;
};
export type WebApiV1CreateOarApiResponse = /** status 200 OK */ OarsOut;
export type WebApiV1CreateOarApiArg = {
  siteGroupId: number;
};
export type WebApiV1UpdateOarApiResponse = /** status 200 OK */ OarsOut;
export type WebApiV1UpdateOarApiArg = {
  oarId: number;
  oarsOut: OarsOut;
};
export type WebApiV1DeleteOarApiResponse = unknown;
export type WebApiV1DeleteOarApiArg = {
  oarId: number;
};
export type WebApiV1ListTargetObjectivesApiResponse =
  /** status 200 OK */ TargetObjectiveOut[];
export type WebApiV1ListTargetObjectivesApiArg = {
  siteId: number;
};
export type WebApiV1UpdateTargetObjectiveApiResponse =
  /** status 200 OK */ TargetObjectiveOut;
export type WebApiV1UpdateTargetObjectiveApiArg = {
  siteId: number;
  targetObjectiveId: number;
  targetObjectiveIn: TargetObjectiveIn;
};
export type WebApiV1ListAttachmentsApiResponse =
  /** status 200 OK */ ScorecardAttachmentOut;
export type WebApiV1ListAttachmentsApiArg = {
  orderId: number;
};
export type WebApiV1CreateAttachmentApiResponse = unknown;
export type WebApiV1CreateAttachmentApiArg = {
  orderId: number;
  scorecardAttachmentIn: ScorecardAttachmentIn;
};
export type WebApiV1DeleteAttachmentApiResponse = unknown;
export type WebApiV1DeleteAttachmentApiArg = {
  orderId: number;
  attachmentId: number;
};
export type WebApiV1ReadAttachmentApiResponse = unknown;
export type WebApiV1ReadAttachmentApiArg = {
  orderId: number;
  attachmentId: number;
};
export type WebApiV1ListWalkthroughAttachmentsApiResponse =
  /** status 200 OK */ WalkthroughAttachmentOut[];
export type WebApiV1ListWalkthroughAttachmentsApiArg = {
  orderId: number;
};
export type WebApiV1CreateWalkthroughAttachmentUploadsApiResponse =
  /** status 200 OK */ WalkthroughAttachmentUpload[];
export type WebApiV1CreateWalkthroughAttachmentUploadsApiArg = {
  orderId: number;
  fileNames: string[];
};
export type WebApiV1DeleteWalkthroughAttachmentApiResponse = unknown;
export type WebApiV1DeleteWalkthroughAttachmentApiArg = {
  orderId: number;
  attachmentId: number;
};
export type WebApiV1UpdateWalkthroughAttachmentApiResponse =
  /** status 200 OK */ WalkthroughAttachmentOut;
export type WebApiV1UpdateWalkthroughAttachmentApiArg = {
  orderId: number;
  fileId: number;
  uploadComplete?: boolean | null;
  sentForReview?: boolean | null;
  read?: boolean | null;
};
export type CommentsApiV1GetCommentForOrderApiResponse =
  /** status 200 OK */ CommentOut[];
export type CommentsApiV1GetCommentForOrderApiArg = {
  orderGroupId: number;
};
export type CommentsApiV1PostCommentApiResponse = /** status 200 OK */ number;
export type CommentsApiV1PostCommentApiArg = {
  orderGroupId: number;
  commentIn: CommentIn;
};
export type CommentsApiV1CreateAttachmentApiResponse =
  /** status 200 OK */ UploadPayload;
export type CommentsApiV1CreateAttachmentApiArg = {
  orderGroupId: number;
  attachmentIn: AttachmentIn;
};
export type CommentsApiV1GetUnreadCommentCountApiResponse =
  /** status 200 OK */ number;
export type CommentsApiV1GetUnreadCommentCountApiArg = {
  orderGroupId: number;
};
export type CommentsApiV1GetAllUnreadCommentsApiResponse =
  /** status 200 OK */ OrderCommentCount[];
export type CommentsApiV1GetAllUnreadCommentsApiArg = void;
export type CommentsApiV1SetCommentsReadApiResponse =
  /** status 200 OK */ boolean;
export type CommentsApiV1SetCommentsReadApiArg = {
  orderGroupId: number;
};
export type WebApiV1UpdateUserActivityLastViewedApiResponse =
  /** status 200 OK */ string;
export type WebApiV1UpdateUserActivityLastViewedApiArg = void;
export type WebApiV1ResetDemoDataApiResponse = /** status 200 OK */ boolean;
export type WebApiV1ResetDemoDataApiArg = void;
export type AutomationIntegrationApiV1GetStructuresApiResponse =
  /** status 200 OK */ StructureSetResponse | null;
export type AutomationIntegrationApiV1GetStructuresApiArg = {
  orderId: number;
};
export type AutomationIntegrationApiV1ListTemplatesApiResponse =
  /** status 200 OK */ TemplateListSerialised[];
export type AutomationIntegrationApiV1ListTemplatesApiArg = void;
export type AutomationIntegrationApiV1ListTemplateDetailsApiResponse =
  /** status 200 OK */ TemplateOptionSerialised;
export type AutomationIntegrationApiV1ListTemplateDetailsApiArg = {
  templateName: string;
};
export type AutomationIntegrationApiV1GetOrderEventsApiResponse =
  /** status 200 OK */ EventOut[];
export type AutomationIntegrationApiV1GetOrderEventsApiArg = {
  orderId: number;
};
export type AutomationIntegrationApiV1GetOrderTreatmentPlanResultApiResponse =
  /** status 200 OK */ PlanResponseOut[];
export type AutomationIntegrationApiV1GetOrderTreatmentPlanResultApiArg = {
  orderId: number;
};
export type AutomationIntegrationApiV1SendRequestApiResponse =
  /** status 200 OK */ string;
export type AutomationIntegrationApiV1SendRequestApiArg = {
  orderId: number;
};
export type AutomationIntegrationApiV1SendPlanGenerationApiResponse =
  /** status 200 OK */ boolean;
export type AutomationIntegrationApiV1SendPlanGenerationApiArg = {
  orderId: number;
  structureMapIn: StructureMapIn;
};
export type AutomationIntegrationApiV1CreateMockStatusApiResponse =
  /** status 200 OK */ string;
export type AutomationIntegrationApiV1CreateMockStatusApiArg = {
  orderId: number;
  statusType: string;
  eventDetails: string | null;
};
export type AutomationIntegrationApiV1MockImportedCtRtStructResponseApiResponse =
  /** status 200 OK */ boolean;
export type AutomationIntegrationApiV1MockImportedCtRtStructResponseApiArg = {
  orderId: number;
  responseType: "empty" | "ct_only" | "ct_rt_struct";
};
export type AutomationIntegrationApiV1MockPendingCtRtStructResponseApiResponse =
  /** status 200 OK */ boolean;
export type AutomationIntegrationApiV1MockPendingCtRtStructResponseApiArg = {
  orderId: number;
  responseType: "empty" | "ct_only" | "ct_rt_struct";
};
export type WebApiV1ListAppointmentsApiResponse =
  /** status 200 OK */ AppointmentOut[];
export type WebApiV1ListAppointmentsApiArg = {
  startDate: string | null;
  endDate: string | null;
  userFilter?: boolean;
};
export type NotificationsApiV1RegisterUserDeviceApiResponse =
  /** status 200 OK */ boolean;
export type NotificationsApiV1RegisterUserDeviceApiArg = {
  registerDevice: RegisterDevice;
};
export type NotificationsApiV1IsDeviceRegisteredApiResponse =
  /** status 200 OK */ boolean;
export type NotificationsApiV1IsDeviceRegisteredApiArg = {
  deviceToken: string;
};
export type NotificationsApiV1GetActivityCountApiResponse =
  /** status 200 OK */ number;
export type NotificationsApiV1GetActivityCountApiArg = void;
export type NotificationsApiV1GetNotificationsApiResponse =
  /** status 200 OK */ NotificationOut[];
export type NotificationsApiV1GetNotificationsApiArg = {
  deviceToken: string;
};
export type NotificationsApiV1UpdateNotificationViewedApiResponse =
  /** status 200 OK */ boolean;
export type NotificationsApiV1UpdateNotificationViewedApiArg = {
  notificationId: number;
};
export type NotificationsApiV1UpdateNotificationsApiResponse =
  /** status 200 OK */ boolean;
export type NotificationsApiV1UpdateNotificationsApiArg = {
  orderGroupId: number;
  notificationIn: NotificationIn;
};
export type ProtocolInfoOut = {
  id: number;
  name: string;
  tumourStream: string;
  siteFilter: string;
  regions: string[];
  subregions: string[];
};
export type PagedProtocolInfoOut = {
  items: ProtocolInfoOut[];
  count: number;
};
export type ListDataOptionSerialised = {
  value: string;
  category: string;
  tenant: string;
  list_weight: number;
  is_active: boolean;
};
export type TreatmentSiteSerialised = {
  name: string;
  default_laterality: string;
};
export type OrganSerialised = {
  name: string;
};
export type PatientOut = {
  id: number;
  firstName: string | null;
  lastName: string | null;
  dateOfBirth: string | null;
  medicalReferenceNumber: string | null;
};
export type PatientIn = {
  firstName?: string | null;
  lastName?: string | null;
  dateOfBirth?: string | null;
  medicalReferenceNumber?: string | null;
};
export type OrderStatus =
  | "draft"
  | "submitted"
  | "in_progress"
  | "ready_for_ro_approval"
  | "approved"
  | "completed"
  | "rejected"
  | "sent_to_automation"
  | "manual_plan";
export type StructureStatus = "complete" | "pending";
export type OrderOut = {
  id: number;
  parentId: number | null;
  orderGroupId: number;
  orderName: string | null;
  status: OrderStatus;
  createdAt: string | null;
  submittedDatetime: string | null;
  patientId: number | null;
  patientName: string | null;
  patientMrn: string | null;
  patientDob: string | null;
  treatingDepartment: string | null;
  planDelivery: string | null;
  formattedStatus: string;
  additionalInfo: string | null;
  isLatest: boolean;
  treatingRo: string;
  treatingRoId: number | null;
  editingDatetime: string | null;
  approvedDatetime: string | null;
  approvedOnBehalfName: string | null;
  doseUnit: string | null;
  fractionUnit: string | null;
  createdFromLumonusAi: boolean | null;
  ctStatus: StructureStatus | null;
  rtstructStatus: StructureStatus | null;
};
export type OrderIn = {
  treatingDepartment?: string | null;
  planDelivery?: string | null;
  status?: OrderStatus | null;
  additionalInfo?: string | null;
  treatingRoId?: number | null;
};
export type PagedOrderOut = {
  items: OrderOut[];
  count: number;
};
export type RejectOrderIn = {
  rejectReason: string[];
  rejectedAdditionalInfo: string | null;
};
export type LumonusAiOrderOut = {
  orderId: number;
  orderGroupId: number;
  patientId: number;
};
export type LumonusAiPatientIn = {
  id: number;
  ida: string;
  dob: string;
  lastName: string;
  firstName: string;
};
export type LumonusAiCareplanIn = {
  id: number;
  name: string;
  priority: string;
  submittedDttm: string;
};
export type LumonusAiPractitionerIn = {
  id: number;
  name: string;
  email: string;
};
export type LumonusAiSubmittingUserIn = {
  name: string;
  email: string;
  username: string;
};
export type LumonusAiOrderIn = {
  patient: LumonusAiPatientIn;
  careplan: LumonusAiCareplanIn;
  practitioner: LumonusAiPractitionerIn;
  submittingUser: LumonusAiSubmittingUserIn;
};
export type StatusChangeOut = {
  id: number;
  orderId: number;
  status: OrderStatus;
  createdAt: string;
  updatedAt: string;
  createdBy: string | null;
};
export type SiteGroupOut = {
  id: string | number;
  name: string;
  protocolId: number | null;
  protocolName: string | null;
  bolusActive: boolean;
  bolusFrequency: string | null;
  bolusLocation: string | null;
  bolusThickness: string | null;
  additionalInformation?: string | null;
  createdAt: string;
};
export type SiteGroupIn = {
  name?: string | null;
  bolusActive: boolean | null;
  bolusFrequency?: string | null;
  bolusLocation?: string | null;
  bolusThickness?: string | null;
  additionalInformation?: string | null;
};
export type SiteOut = {
  id: string | number;
  siteGroupId: number;
  name: string;
  nameSpecify?: string | null;
  laterality: string;
  technique: string;
  sequence: string;
  isodose: string;
  highDose: number | null;
  lowDose?: number | null;
  mid1Dose?: number | null;
  mid2Dose?: number | null;
  fractions: number | null;
  frequency: string;
  energy: string;
  createdAt: string;
};
export type OarsOut = {
  id: number;
  siteGroupId: number;
  organ: string;
  constraints: string;
  sortIndex: number;
  createdAt: string;
};
export type TargetObjectiveOut = {
  id: number;
  siteId: number;
  doseType: string;
  gtv: string;
  ptv: string;
  ctv: string;
  createdAt: string;
};
export type TargetObjectiveIn = {
  gtv: string;
  ptv: string;
  ctv: string;
};
export type DosimetricCriteria = {
  Criterion: string;
  Outcome: string;
  Actual: string;
};
export type DvhStatistics = {
  Structure: string;
  DosimetricCriteria: DosimetricCriteria[];
  IsTarget?: boolean | null;
};
export type PlanApproval = {
  Timestamp?: string;
};
export type ScorecardObject = {
  DVHStatistics: DvhStatistics[];
  PlanApproval: PlanApproval;
};
export type FileAttachmentOut = {
  id: number;
  scorecard?: string;
  scorecardObj: ScorecardObject;
  lastModifiedDate: string;
  createdAt: string;
  fileName: string;
  planLockedTimestamp?: string;
  read: boolean;
};
export type ScorecardAttachmentOut = {
  id: number;
  scorecards: FileAttachmentOut[];
};
export type ScorecardAttachmentIn = {
  fileName: string;
  scorecard: ScorecardObject;
};
export type WalkthroughAttachmentOut = {
  id: number;
  lastModifiedDate: string;
  createdAt: string;
  file: string;
  fileName: string;
  fileSize: number;
  isValid: boolean;
  sentForReview: boolean;
  read: boolean;
};
export type PresignedFields = {
  AWSAccessKeyId?: string | null;
  key: string;
  policy: string;
  signature?: string | null;
  "x-amz-signature"?: string | null;
  "x-amz-security-token": string;
  "x-amz-credential"?: string | null;
  "x-amz-algorithm"?: string | null;
  "x-amz-date"?: string | null;
};
export type UploadPayload = {
  url: string;
  attachment_id?: number | null;
  fields: PresignedFields;
};
export type WalkthroughAttachmentUpload = {
  id: number;
  uploadUrl: UploadPayload;
  fileName: string;
};
export type CommentAttachmentOut = {
  id: number;
  fileName: string;
  url: string;
};
export type CommentOut = {
  id: number;
  createdBy: string;
  creatorRole: string;
  content?: string | null;
  createdAt?: string | null;
  attachments: CommentAttachmentOut[];
};
export type CommentIn = {
  orderId: number;
  content: string;
  attachmentIds?: number[] | null;
};
export type AttachmentIn = {
  fileName: string;
};
export type OrderCommentCount = {
  orderGroupId: number;
  commentCount: number;
};
export type StructureSetEntry = {
  name: string;
  type: string;
};
export type StructureSetResponse = {
  structures: StructureSetEntry[];
};
export type TemplateListSerialised = {
  name: string;
};
export type TemplateStructureOptions = {
  key: string;
  value: string;
};
export type TemplateStructure = {
  name: string;
  mandatory: boolean;
  structure: TemplateStructureOptions[];
};
export type TemplateOptionSerialised = {
  name: string;
  data: TemplateStructure[];
};
export type EventType = "planning_step_sequence" | "planning_step" | "warning";
export type EventOut = {
  event_type: EventType;
  event_details: string | string[];
  updated_at: string;
};
export type PlanResponseOut = {
  result: boolean;
  plan_name: string;
  error?: string | null;
  updated_at: string;
};
export type StructureMapIn = {
  template: string;
  structures: StructureSetEntry[];
};
export type Patient = {
  id: number;
  firstName: string;
  lastName: string;
  dob: string | null;
};
export type AppointmentOut = {
  id: number;
  patient: Patient | null;
  startTime: string;
  duration: string | null;
  status: string;
  description: string | null;
  location: string | null;
  comment: string | null;
  activity: string | null;
  department: string | null;
  appointmentType: string;
  appointmentCategory: string;
};
export type RegisterDevice = {
  deviceToken: string;
};
export type NotificationOut = {
  id: number | null;
  orderId: number | null;
  orderGroupId: number | null;
  content: string | null;
  viewed: boolean | null;
  createdAt: string;
  patientName: string | null;
  patientDob: string | null;
  patientMrn: string | null;
  notificationType: string | null;
  createdBy: string | null;
};
export type NotificationIn = {
  notificationType: string;
};
export const {
  useProtocolApiV1ListProtocolsQuery,
  useProtocolApiV1ListDataQuery,
  useProtocolApiV1TreatmentSiteDataQuery,
  useProtocolApiV1OrganDataQuery,
  useWebApiV1GetPatientQuery,
  useWebApiV1UpdatePatientMutation,
  useWebApiV1OrderQuery,
  useWebApiV1UpdateOrderMutation,
  useWebApiV1DeleteClonedOrderMutation,
  useWebApiV1CreateOrderMutation,
  useWebApiV1ListOrdersQuery,
  useWebApiV1CloneOrderMutation,
  useWebApiV1RejectOrderMutation,
  useWebApiV1CreateFhirOrderMutation,
  useWebApiV1OrderStatusChangeQuery,
  useWebApiV1GetTenantPhysiciansQuery,
  useWebApiV1GetUserFeatureSwitchesQuery,
  useWebApiV1GetFeatureSwitchesForOrderQuery,
  useWebApiV1ListSiteGroupsQuery,
  useWebApiV1CreateSiteGroupMutation,
  useWebApiV1UpdateSiteGroupMutation,
  useWebApiV1DeleteSiteGroupMutation,
  useWebApiV1ListSitesQuery,
  useWebApiV1CreateSiteMutation,
  useWebApiV1UpdateSiteMutation,
  useWebApiV1DeleteSiteMutation,
  useWebApiV1ListOarsQuery,
  useWebApiV1CreateOarMutation,
  useWebApiV1UpdateOarMutation,
  useWebApiV1DeleteOarMutation,
  useWebApiV1ListTargetObjectivesQuery,
  useWebApiV1UpdateTargetObjectiveMutation,
  useWebApiV1ListAttachmentsQuery,
  useWebApiV1CreateAttachmentMutation,
  useWebApiV1DeleteAttachmentMutation,
  useWebApiV1ReadAttachmentMutation,
  useWebApiV1ListWalkthroughAttachmentsQuery,
  useWebApiV1CreateWalkthroughAttachmentUploadsMutation,
  useWebApiV1DeleteWalkthroughAttachmentMutation,
  useWebApiV1UpdateWalkthroughAttachmentMutation,
  useCommentsApiV1GetCommentForOrderQuery,
  useCommentsApiV1PostCommentMutation,
  useCommentsApiV1CreateAttachmentMutation,
  useCommentsApiV1GetUnreadCommentCountQuery,
  useCommentsApiV1GetAllUnreadCommentsQuery,
  useCommentsApiV1SetCommentsReadMutation,
  useWebApiV1UpdateUserActivityLastViewedMutation,
  useWebApiV1ResetDemoDataMutation,
  useAutomationIntegrationApiV1GetStructuresQuery,
  useAutomationIntegrationApiV1ListTemplatesQuery,
  useAutomationIntegrationApiV1ListTemplateDetailsQuery,
  useAutomationIntegrationApiV1GetOrderEventsQuery,
  useAutomationIntegrationApiV1GetOrderTreatmentPlanResultQuery,
  useAutomationIntegrationApiV1SendRequestMutation,
  useAutomationIntegrationApiV1SendPlanGenerationMutation,
  useAutomationIntegrationApiV1CreateMockStatusMutation,
  useAutomationIntegrationApiV1MockImportedCtRtStructResponseMutation,
  useAutomationIntegrationApiV1MockPendingCtRtStructResponseMutation,
  useWebApiV1ListAppointmentsQuery,
  useNotificationsApiV1RegisterUserDeviceMutation,
  useNotificationsApiV1IsDeviceRegisteredQuery,
  useNotificationsApiV1GetActivityCountQuery,
  useNotificationsApiV1GetNotificationsQuery,
  useNotificationsApiV1UpdateNotificationViewedMutation,
  useNotificationsApiV1UpdateNotificationsMutation,
} = injectedRtkApi;
