import { useState } from "react";
import PortaledRightDrawer from "@pages/Layout/PortaledRightDrawer";
import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  Stack,
  ListItemButton,
  Skeleton,
} from "@mui/material";
import { Close as CloseIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { SiteGroupOut } from "@providers/hop-ord-server/api";
import { useTheme, CustomTheme } from "@mui/material";
import { Link } from "react-scroll";

const scrollToId = (id: string) => {
  const element = document.getElementById(id);
  element?.scrollIntoView({ behavior: "smooth" });
};

const ContentsLink = ({
  text,
  targetId,
  onClick,
}: {
  text: string;
  targetId: string;
  onClick: () => void;
}) => (
  <ListItem
    sx={{
      color: useTheme().palette.primary.main,
      ":hover": { textDecoration: "underline", cursor: "pointer" },
    }}
    onClick={() => {
      onClick();
      scrollToId(targetId);
    }}
  >
    {text}
  </ListItem>
);

interface Props {
  addedSiteGroups: SiteGroupOut[];
  permanent?: boolean;
  onDeleteSiteGroup: (siteGroup: SiteGroupOut) => void;
  open?: boolean;
  setOpen: (open: boolean) => void;
  isLoading: boolean;
}

const RightDrawer = ({
  addedSiteGroups,
  permanent = false,
  onDeleteSiteGroup,
  open = false,
  setOpen,
  isLoading,
}: Props) => {
  const theme = useTheme<CustomTheme>();
  const [activeSiteGroup, setActiveSiteGroup] = useState<
    null | number | string
  >(null);

  // Highlist ID of Added Site Groups
  const handleSetActive = (to: number | string) => {
    setActiveSiteGroup(to);
  };

  // Remove Highlights if scroll to top of page
  const handleSetInactive = (idx: number) => {
    if (idx === 0) {
      setActiveSiteGroup(null);
    }
  };

  return (
    <PortaledRightDrawer
      open={open}
      permanent={permanent}
      setOpen={setOpen}
      drawerWidth={320}
    >
      <Stack paddingY={permanent ? 1 : 0} data-testid="order-right-drawer">
        {!permanent && (
          <Box padding={1}>
            <IconButton
              size="small"
              color="default"
              onClick={() => setOpen(false)}
              data-testid="right-drawer-close-button"
            >
              <CloseIcon />
            </IconButton>
          </Box>
        )}
        {isLoading ? (
          <Stack data-testid="loading-right-drawer" padding={2}>
            <Skeleton variant="text" width={80} />
            <Skeleton variant="text" width={110} sx={{ marginLeft: 2 }} />
            <Skeleton variant="text" width={130} sx={{ marginLeft: 2 }} />
            <Skeleton variant="text" width={160} sx={{ marginTop: 2 }} />
            <Skeleton variant="text" width={250} height={48} />
            <Skeleton
              variant="text"
              width={250}
              height={48}
              sx={{ bgColor: theme.palette.skeleton.levelOne }}
            />
          </Stack>
        ) : (
          <Box paddingX={2}>
            <List dense disablePadding>
              <ListSubheader disableGutters>CONTENTS</ListSubheader>
              <ContentsLink
                text="Patient Details"
                targetId="patient-details"
                onClick={() => setOpen(permanent)}
              />
              <ContentsLink
                text="Planning Protocol"
                targetId="planning-protocol"
                onClick={() => setOpen(permanent)}
              />
            </List>
            <List sx={{ width: 1 }} dense disablePadding>
              <ListSubheader key="subheader" disableGutters>
                ADDED SITE GROUPS
              </ListSubheader>
              {addedSiteGroups.map((siteGroup, idx) => (
                // @ts-expect-error - Seems that the types package for react-scroll is slightly out of date
                <Link
                  to={`site-group-panel-${siteGroup.id}`}
                  key={idx}
                  spy={true}
                  smooth={true}
                  offset={-280}
                  duration={500}
                  onSetActive={() => handleSetActive(siteGroup.id)}
                  onSetInactive={() => handleSetInactive(idx)}
                >
                  <ListItemButton
                    key={siteGroup.id}
                    onClick={() => {
                      setOpen(permanent);
                    }}
                    data-testid={`site-group-${idx}`}
                    sx={{
                      ...(siteGroup.id == activeSiteGroup && {
                        backgroundColor: theme.palette.secondary.main,
                        borderLeft: `4px solid ${theme.palette.primary.main}`,
                      }),
                    }}
                  >
                    <ListItemText primaryTypographyProps={{ variant: "body2" }}>
                      {siteGroup.name}
                    </ListItemText>
                    <IconButton
                      onClick={(e) => {
                        e.stopPropagation();
                        onDeleteSiteGroup(siteGroup);
                      }}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </ListItemButton>
                </Link>
              ))}
            </List>
          </Box>
        )}
      </Stack>
    </PortaledRightDrawer>
  );
};

export default RightDrawer;
