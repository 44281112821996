import {
  Card,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Button, PageHeader } from "@components";
import { useNavigate } from "react-router-dom";
import {
  Warning,
  PlaylistAddCheck,
  ArrowDownward,
  CheckCircle,
} from "@mui/icons-material";
import { theme } from "@theme";
import { toLocaleDateTimeString } from "@utils";
import { useCallback, useMemo } from "react";
import {
  OrderOut,
  useWebApiV1ListOrdersQuery,
} from "@providers/hop-ord-server/api";
import { useAppSelector } from "@hooks";
import { StructureStatus, OrderStatus } from "@enums";
import { CtRtMockComponent } from "./CtRtMockComponent";

const DEMO_TENANTS = [
  "tenant:demo",
  "tenant:demo-au",
  "tenant:demo-planning",
  "tenant:demo-planning-au",
];

const CommandCenter = () => {
  const tenants = useAppSelector((state) => state?.auth?.tenants);
  const isDemoTenant =
    tenants.filter((value) => DEMO_TENANTS.includes(value)).length > 0;

  const navigate = useNavigate();

  const { data } = useWebApiV1ListOrdersQuery({
    limit: 20,
  });

  const navToTracker = useCallback(
    (orderId: number, orderStatus: string) => {
      const urlEnd =
        orderStatus === OrderStatus.SENT_TO_AUTOMATION
          ? "/automated"
          : orderStatus === OrderStatus.MANUAL_PLAN
            ? "/manual"
            : "";
      return navigate(`/fulfillment/${orderId}/automation${urlEnd}`);
    },
    [navigate],
  );

  const orderData = useMemo(
    () =>
      data?.items?.filter(
        (order) => order.status !== "draft", //&& order?.createdFromLumonusAi,
      ),
    [data?.items],
  );

  const planDeliveryColor = (planDelivery: string | null) => {
    if (planDelivery === "emergency") return theme.palette.error.dark;
    if (planDelivery === "urgent") return theme.palette.warning.dark;
    return theme.palette.text.primary;
  };

  const structStatusColor = (status: string | null) => {
    switch (status) {
      case StructureStatus.COMPLETE:
      case StructureStatus.PENDING:
        return theme.palette.success.light;
      default:
        return theme.palette.warning.light;
    }
  };

  const structStatusIcon = (status: string | null) => {
    switch (status) {
      case StructureStatus.COMPLETE:
      case StructureStatus.PENDING:
        return <CheckCircle color="success" fontSize="large" />;
      default:
        return <Warning color="warning" fontSize="large" />;
    }
  };

  return (
    <Stack gap={3} paddingBottom={2} paddingX={2}>
      <PageHeader title="Planning Command Center" />
      {orderData?.length ? (
        <Stack direction="row" alignItems="center" gap={2}>
          <TextField id="standard-basic" label="Search..." variant="standard" />

          <Button
            variant="text"
            startIcon={<PlaylistAddCheck color="secondary" />}
          >
            Status
          </Button>
        </Stack>
      ) : null}

      {!orderData?.length ? (
        <Typography>No orders found</Typography>
      ) : (
        <Card sx={{ overflow: "auto" }}>
          <TableContainer sx={{ overflow: "auto" }}>
            <Table
              size="small"
              sx={{
                minWidth: "1200px",
                "& .MuiTableCell-head": {
                  padding: "16px",
                },
                "& .MuiTableCell-body": {
                  padding: "8px",
                  border: "1px solid #E0E0E0 !important", // important because MUI puts border 0px on the last child
                },
              }}
            >
              <TableHead>
                <TableRow>
                  <TableCell width={"19%"}>Patient</TableCell>
                  <TableCell width={"10%"}>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <ArrowDownward color="secondary" fontSize="small" /> Rx
                      Submitted
                    </Stack>
                  </TableCell>
                  <TableCell width={"20%"} sx={{ textAlign: "center" }}>
                    Prescription
                  </TableCell>
                  <TableCell width={"6%"} sx={{ textAlign: "center" }}>
                    CT
                  </TableCell>
                  <TableCell width={"6%"} sx={{ textAlign: "center" }}>
                    RTStruct
                  </TableCell>
                  <TableCell width={"33%"}>Planning Status</TableCell>
                  <TableCell width={"6%"} sx={{ textAlign: "center" }}>
                    Handover
                  </TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {orderData?.map((order: OrderOut, index) => (
                  <TableRow
                    key={index}
                    sx={{
                      "&:last-child td, &:last-child th": { border: 0 },
                      height: "102px",
                      minHeight: "102px",
                    }}
                  >
                    {/* Patient */}
                    <TableCell component="th" scope="row">
                      <Stack></Stack>
                      <Typography variant="subtitle2">
                        {order.patientName}
                        <br />
                        MRN: {order.patientMrn} | DOB:{" "}
                        {order.patientDob
                          ? toLocaleDateTimeString(order.patientDob, "date")
                          : "-"}
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        Physician: {order.treatingRo}
                      </Typography>
                    </TableCell>

                    {/* Rx Submitted */}
                    <TableCell
                      sx={{ backgroundColor: theme.palette.success.light }}
                    >
                      <Typography variant="body2">
                        {order.submittedDatetime
                          ? toLocaleDateTimeString(order.submittedDatetime)
                          : "-"}
                      </Typography>
                    </TableCell>

                    {/* Prescription */}
                    <TableCell
                      sx={{ backgroundColor: theme.palette.success.light }}
                    >
                      <Stack gap={0.5}>
                        <Typography
                          variant="body2"
                          data-testid={`${index}-prescription-name`}
                        >
                          {order.orderName}
                        </Typography>
                        <Typography
                          variant="body2"
                          color={planDeliveryColor(order?.planDelivery)}
                          sx={{ textTransform: "capitalize" }}
                        >
                          {order?.planDelivery}
                        </Typography>
                      </Stack>
                    </TableCell>

                    {/* CT */}
                    <TableCell
                      sx={{
                        backgroundColor: structStatusColor(order?.ctStatus),
                      }}
                    >
                      <Stack alignItems="center" width={1} gap={1}>
                        {structStatusIcon(order?.ctStatus)}
                        {order?.ctStatus !== StructureStatus.PENDING &&
                          order?.ctStatus !== StructureStatus.COMPLETE && (
                            <Typography
                              color="text.secondary"
                              data-testid={`${index}-ct-status-text`}
                            >
                              Pending
                            </Typography>
                          )}
                        {order?.ctStatus !== StructureStatus.PENDING &&
                          order?.ctStatus !== StructureStatus.COMPLETE &&
                          isDemoTenant && (
                            <CtRtMockComponent
                              orderId={order?.id}
                              ctOnly={true}
                            />
                          )}
                      </Stack>
                    </TableCell>

                    {/* RTStruct */}
                    <TableCell
                      sx={{
                        backgroundColor: structStatusColor(
                          order?.rtstructStatus,
                        ),
                      }}
                    >
                      <Stack alignItems="center" width={1} gap={1}>
                        {structStatusIcon(order?.rtstructStatus)}
                        {order?.rtstructStatus !== StructureStatus.PENDING &&
                          order?.rtstructStatus !==
                            StructureStatus.COMPLETE && (
                            <Typography
                              color="text.secondary"
                              data-testid={`${index}-rtstruct-status-text`}
                            >
                              Pending
                            </Typography>
                          )}

                        {order?.rtstructStatus !== StructureStatus.PENDING &&
                          order?.rtstructStatus !== StructureStatus.COMPLETE &&
                          isDemoTenant && (
                            <CtRtMockComponent
                              orderId={order?.id}
                              ctOnly={false}
                            />
                          )}
                      </Stack>
                    </TableCell>

                    {/* Planning Status */}
                    <TableCell
                      sx={{ backgroundColor: theme.palette.grey[100] }}
                    >
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                        paddingX={1}
                      >
                        {order?.status === "submitted" ? (
                          <Button
                            color="secondary"
                            variant="contained"
                            onClick={() =>
                              navToTracker(order?.id, order?.status)
                            }
                          >
                            Ready for planning
                          </Button>
                        ) : (
                          <>
                            <Typography variant="body2">
                              Planning Status
                            </Typography>
                            <Button
                              onClick={() =>
                                navToTracker(order?.id, order?.status)
                              }
                              variant="outlined"
                            >
                              See more
                            </Button>
                          </>
                        )}
                      </Stack>
                    </TableCell>

                    {/* Handover */}
                    <TableCell
                      sx={{ backgroundColor: theme.palette.grey[100] }}
                    >
                      <Stack alignItems="center" width={1} gap={1}>
                        <Typography color="text.secondary" variant="body2">
                          Not started
                        </Typography>
                      </Stack>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Card>
      )}
    </Stack>
  );
};

export default CommandCenter;
