import {
  Box,
  Card,
  Stack,
  Typography,
  Divider,
  LinearProgress,
} from "@mui/material";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";

import dayjs from "dayjs";

const PlanAutomationPanel = (): JSX.Element => {
  return (
    <Stack gap={4}>
      <Card>
        <Box flex={1}>
          <Stack padding={2} width={1}>
            <Typography variant="subtitle1">Planning automation</Typography>
            <Typography variant="body2" color="text.secondary">
              Not started
            </Typography>
            <LinearProgress
              sx={{ marginBottom: 2, marginTop: 3 }}
              variant="determinate"
              value={20}
            />
            <Typography variant="body2" color="text.secondary">
              Queued
            </Typography>
          </Stack>
        </Box>
      </Card>
      <Divider sx={{ width: 1 }} />
      <Box flex={1}>
        <Stack width={1}>
          <Typography variant="h6">Handover</Typography>
          <Typography variant="body2" paddingY={1}>
            By marking this as complete, you confirm that the physician has
            approved the plan and the handover process with the local planning
            team is finished
          </Typography>
          <MobileDateTimePicker
            sx={{ marginTop: 2, width: "max-content" }}
            label="Date time completed"
            defaultValue={dayjs()}
            format="L hh:mm a"
          />
        </Stack>
      </Box>
    </Stack>
  );
};

export default PlanAutomationPanel;
