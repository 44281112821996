import {
  OrderStatus as OrderStatusType,
  StructureStatus as StructureStatusType,
} from "@providers/hop-ord-server/api";
import { PaletteColour } from "../theme/theme";

export enum Feature {
  AUTOMATION_INTEGRATION = "automation_integration",
  NEW_COMMAND_CENTER = "real_command_center",
}

export enum Role {
  RO = "role:ro",
  DOSIMETRIST = "role:planner",
}

export enum ListDataCategory {
  TREATMENT_SITE = "treatmentSite",
  ISODOSE = "isodose",
  LATERALITY = "laterality",
  TECHNIQUE = "technique",
  SEQUENCE = "sequence",
  FREQUENCY = "frequency",
  ENERGY = "energy",
  ORGAN = "organ",
  REJECT_REASON = "reject_reason",
  TREATING_DEPARTMENT = "treating_department",
}

export const DASH_EMPTY_FIELD = "-";

export const OrderStatus: Record<string, OrderStatusType> = {
  DRAFT: "draft",
  SUBMITTED: "submitted",
  IN_PROGRESS: "in_progress",
  READY_FOR_RO_APPROVAL: "ready_for_ro_approval",
  APPROVED: "approved",
  REJECTED: "rejected",
  COMPLETED: "completed",
  SENT_TO_AUTOMATION: "sent_to_automation",
  MANUAL_PLAN: "manual_plan",
};

export const orderStatusMapping: { [key in OrderStatusType]: string } = {
  draft: "Draft",
  submitted: "Submitted",
  in_progress: "In Progress",
  ready_for_ro_approval: "Ready for RO approval",
  approved: "Approved",
  rejected: "Rejected",
  completed: "Completed",
  sent_to_automation: "Sent to Automation",
  manual_plan: "Manual Plan",
};

export const orderStatusColourMapping: {
  [key in OrderStatusType]: PaletteColour;
} = {
  draft: "default",
  submitted: "secondary",
  in_progress: "info",
  ready_for_ro_approval: "warning",
  approved: "success",
  rejected: "error",
  completed: "primary",
  sent_to_automation: "success",
  manual_plan: "success",
};

export const StructureStatus: Record<string, StructureStatusType> = {
  COMPLETE: "complete",
  PENDING: "pending",
};
