import { ReactNode } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {
  AppBar,
  Toolbar,
  Stack,
  Avatar,
  IconButton,
  Link,
  Tooltip,
  Box,
} from "@mui/material";
import { User } from "@auth0/auth0-react";
import LogoutIcon from "@mui/icons-material/Logout";
import Notifications from "@components/Notifications/Notifications";

interface MainLayoutProps {
  user?: User;
  accessToken?: boolean;
  children: ReactNode;
}

const Layout: React.FC<MainLayoutProps> = ({ user, accessToken, children }) => {
  const { logout: auth0Logout } = useAuth0();

  const logout = () => {
    auth0Logout({ logoutParams: { returnTo: window.location.origin } });
    location.reload();
  };

  const logoURL = new URL("@assets/header_logo.png", import.meta.url).href;
  return (
    <>
      <AppBar
        position="fixed"
        color="primary"
        id="app-bar"
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
      >
        <Toolbar>
          <Stack flex={1}>
            <Link
              href="/"
              width="max-content"
              underline="none"
              color="inherit"
              variant="h6"
              style={{ display: "inline-flex" }}
            >
              <img src={logoURL} alt="logo" style={{ height: "60px" }} />
            </Link>
          </Stack>
          <Stack direction="row" alignItems="center" gap={1}>
            {user && accessToken && <Notifications />}
            <Tooltip
              title={`${user?.name} <${user?.email}>`}
              placement="top-start"
            >
              <Avatar />
            </Tooltip>
            <IconButton color="inherit" onClick={logout}>
              <LogoutIcon />
            </IconButton>
          </Stack>
        </Toolbar>
      </AppBar>

      {/* Main Content */}
      {/* There are redundant stacks because they make the layout work properly with the MUI tables.
       * If you can figure out a simpler way, please do!
       * When testing, make sure to resize the screen and check that the content doesn't go under the
       * sidebar.
       */}
      <Stack flex={1} height="calc(100vh - 64px)" marginTop={8} width={1}>
        <Stack flex={1} direction="row">
          <Stack flex={1} overflow="clip" width={1} minWidth={0}>
            <Box id="main-banner" />
            <Box padding={2}>{children}</Box>
          </Stack>
          <Box id="layout-right-bar" />
        </Stack>
      </Stack>
    </>
  );
};

export default Layout;
