import { CustomTheme, createTheme } from "@mui/material/styles";
import { borderColor } from "./colors";
import { grey } from "@mui/material/colors";
import React from "react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";

const LinkBehavior = React.forwardRef<
  HTMLAnchorElement,
  Omit<RouterLinkProps, "to"> & { href: RouterLinkProps["to"] }
>((props, ref) => {
  const { href, ...other } = props;
  // Map href (Material UI) -> to (react-router)
  return <RouterLink ref={ref} to={href} {...other} />;
});

const { palette } = createTheme({
  palette: {
    text: {
      primary: "#081e43",
      secondary: "#081e4399",
      disabled: "#081e4360",
    },
    primary: {
      main: "#081E43",
      dark: "#05152E",
      light: "#394B68",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#aaa1fd",
      dark: "#745de5",
      light: "#cdc6fe",
      contrastText: "#081E43",
    },
    action: {
      active: "#aaa1fd",
      activatedOpacity: 1,
      hover: "rgb(116, 93, 229, 0.08)",
      hoverOpacity: 0.04,
      selected: "rgb(116, 93, 229, 0.12)",
      selectedOpacity: 0.12,
      focus: "rgb(116, 93, 229, 0.16)",
      focusOpacity: 0.16,
      disabledBackground: "#0000001e",
      disabled: "rgb(0, 0, 0, 0.38)",
      disabledOpacity: 0.38,
    },
    error: {
      main: "#FB6A76",
      dark: "#ec0031",
      light: "#FFF0F1",
      contrastText: "#081E43",
    },
    warning: {
      main: "#FFBD5B",
      dark: "#C55011",
      light: "#FFF8EF",
      contrastText: "#081E43",
    },
    info: {
      main: "#65ABE8",
      dark: "#226bc0",
      light: "#8FC2EF",
      contrastText: "#081E43",
    },
    success: {
      main: "#3EC490",
      dark: "#007840",
      light: "#ECF9F4",
      contrastText: "#081e43",
    },
    background: {
      default: "#ffffff",
      // all paper elevations are pure white
      paper: "#ffffff",
    },
    borderColor: {
      main: borderColor,
      light: grey[300],
    },
    skeleton: {
      main: grey[300],
      levelOne: grey[200],
      levelTwo: grey[100],
      levelThree: grey[100],
      levelFour: grey[50],
    },
    divider: "#081e431e",
  },
});

const theme: CustomTheme = createTheme({
  palette,
  typography: {
    fontFamily: "Poppins",
  },
  datagrid: {
    backgroundColor: grey[200],
    cell: {
      fontSize: "0.875rem",
      disabledBackgroundColor: grey[200],
      disabledColor: grey[600],
      validationError: "#FBEEEE",
    },
    row: {
      backgroundColor: "white",
      disabledBackgroundColor: grey[200],
    },
  },
  components: {
    MuiLink: {
      defaultProps: {
        component: LinkBehavior,
      },
      styleOverrides: {
        root: {
          ":focus-visible": {
            outlineColor: palette.secondary.main,
          },
        },
      },
    },
    MuiDialogContentText: {
      styleOverrides: {
        root: {
          color: "black",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        // white text when hovered
        contained: {
          ":hover": { color: "white" },
        },
        containedInherit: {
          ":hover": { color: "unset" },
        },
        // darken text of outlined and text variants
        outlinedError: { color: palette.error.dark },
        outlinedInfo: { color: palette.info.dark },
        outlinedWarning: { color: palette.warning.dark },
        outlinedSuccess: { color: palette.success.dark },
        outlinedSecondary: { color: palette.secondary.dark },
        textError: { color: palette.error.dark },
        textInfo: { color: palette.info.dark },
        textWarning: { color: palette.warning.dark },
        textSuccess: { color: palette.success.dark },
        textSecondary: { color: palette.secondary.dark },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        // darken error helper text
        root: {
          "&.Mui-error": {
            color: palette.error.dark,
          },
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          ":hover": { color: "white" },
          ":is(.MuiFab-colorInherit, .MuiFab-default):hover": {
            color: "inherit",
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: palette.secondary.main,
          color: palette.primary.dark,
        },
      },
    },
    MuiBadge: {
      styleOverrides: {
        // "default" colours are error colours
        badge: {
          ":not([class*='MuiBadge-color'])": {
            backgroundColor: palette.error.main,
            color: palette.error.contrastText,
          },
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        // white text when hovered
        filled: {
          "&.MuiChip-clickable:hover": { color: "white" },
          "&.MuiChip-colorDefault:hover": { color: "unset" },
        },
        root: {
          // all avatar colour variants use text.primary besides primary
          ".MuiChip-avatar": {
            color: palette.text.primary,
          },
          ".MuiChip-avatarColorPrimary": {
            color: palette.primary.contrastText,
            backgroundColor: palette.primary.light,
          },
          // avatar background colours are color.light
          ".MuiChip-avatarColorSecondary": {
            backgroundColor: palette.secondary.light,
          },
          ".MuiChip-avatarColorInfo": {
            backgroundColor: palette.info.light,
          },
          ".MuiChip-avatarColorWarning": {
            backgroundColor: palette.warning.light,
          },
          ".MuiChip-avatarColorError": {
            backgroundColor: palette.error.light,
          },
          ".MuiChip-avatarColorDefault": {
            // except default
            backgroundColor: palette.secondary.main,
          },
          // arbitrary delete icon colour :(
          ".MuiChip-deleteIconColorDefault": {
            color: "#430098",
            opacity: 0.26,
          },
          // outlined variant uses dark version of respective colour
          "&.MuiChip-outlinedPrimary": {
            color: palette.primary.dark,
          },
          "&.MuiChip-outlinedSecondary": {
            color: palette.secondary.dark,
          },
          "&.MuiChip-outlinedInfo": {
            color: palette.info.dark,
          },
          "&.MuiChip-outlinedWarning": {
            color: palette.warning.dark,
          },
          "&.MuiChip-outlinedError": {
            color: palette.error.dark,
          },
          "&.MuiChip-outlinedDefault": {
            color: palette.primary.dark,
            borderColor: palette.action.active,
          },
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          // left line and background for selected
          ".MuiListItemButton-root.Mui-selected": {
            backgroundColor: palette.secondary.light,
            borderLeft: `4px solid ${palette.primary.main}`,
          },
          // border radius for all top-level list items
          ".MuiListItem-root, .MuiListItemButton-root": {
            borderRadius: "4px",
          },
          ".MuiListItem-gutters, .MuiListItemButton-gutters": {
            paddingLeft: "12px",
            paddingRight: 0,
          },
          // get rid of jank overlay radius onClick
          ".Mui-selected>.MuiTouchRipple-root": {
            borderRadius: "0px 4px 4px 0px",
          },
        },
      },
    },
    MuiAlert: {
      styleOverrides: {
        root: {
          // close icon of filled variant must be dark
          "&.MuiAlert-filled.MuiAlert-closeIcon": {
            color: palette.text.primary,
          },
        },
      },
    },
    // prefer secondary colour for progress
    MuiLinearProgress: {
      defaultProps: {
        color: "secondary",
      },
    },
    MuiCircularProgress: {
      defaultProps: {
        color: "secondary",
      },
    },
    // snackbar action text should be secondary.light
    MuiSnackbarContent: {
      styleOverrides: {
        action: {
          color: palette.secondary.light,
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          // text and border colours
          ".MuiPaginationItem-outlinedSecondary": {
            borderColor: "#43009839", // totally arbitrary opacity, love it
            "&.Mui-selected": {
              color: palette.secondary.dark,
            },
          },
        },
      },
    },
    MuiStepLabel: {
      // step label uses dark version of color
      styleOverrides: {
        label: {
          "&.Mui-error": {
            color: palette.error.dark,
          },
        },
      },
    },
    MuiStepIcon: {
      // step icons must have 24px min width
      styleOverrides: {
        root: {
          minWidth: "24px",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        // tab text is darker when secondary
        textColorSecondary: {
          "&.Mui-selected": {
            color: palette.secondary.dark,
          },
          "&.Mui-selected svg": {
            color: palette.secondary.main,
          },
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        // tab indicator is darker when secondary
        indicator: ({ ownerState: { indicatorColor } }) => ({
          backgroundColor:
            indicatorColor === "secondary"
              ? palette.secondary.dark
              : palette.primary.main,
        }),
      },
    },
    MuiIconButton: {
      styleOverrides: {
        // default colour is primary
        root: {
          ":not([class*='MuiIconButton-color'])": {
            color: palette.primary.main,
          },
        },
      },
    },
    MuiPopper: {
      styleOverrides: {
        root: {
          "&.MuiAutocomplete-popper>.MuiAutocomplete-paper": {
            ".Mui-selected": {
              backgroundColor: palette.action.focus,
              ":hover": {
                backgroundColor: palette.action.focus,
              },
            },
          },
        },
      },
    },
  },
});

export type PaletteColour =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "info"
  | "success"
  | "text"
  | "skeleton";

export default theme;
